import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Button.scss';

const Button = ({
  text,
  href = '#',
  type = 'button',
  btnType,
  btnSize,
  className,
  disabled,
  children,
  onClick,
  external,
  redirect,
  ...props
}) => (
  <>
    {external ? (
      <a
        href={href}
        className={className}
        target={redirect ? null : '_blank'}
        rel="noopener noreferrer"
        {...props}
      >
        <button
          className={cx(
            styles.btn,
            styles[`btn--${btnType}`],
            styles[`btn--${btnSize}`],
          )}
          type={type}
          disabled={disabled}
          onClick={onClick}
        >
          {text || children}
        </button>
      </a>
    ) : (
      <Link to={href} className={className} {...props}>
        <button
          className={cx(
            styles.btn,
            styles[`btn--${btnType}`],
            styles[`btn--${btnSize}`],
          )}
          type={type}
          disabled={disabled}
          onClick={onClick}
        >
          {text || children}
        </button>
      </Link>
    )}
  </>
);

export default Button;
