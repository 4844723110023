/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PrivateRoute from './routes/PrivateRoute';

import { GlobalStateProvider } from './contexts/globalState';
import './styles/reset.css';
import styles from './App.scss';

import Home from './pages/Home';
import FormTour from './pages/Forms/FormTour';

import Admin from './pages/Admin';
import Login from './pages/Login';
import { AuthContext } from './contexts/AuthContext';

import * as ROUTES from './constants/routes';

const App = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userName, setUserName] = useState(null);
  const GET_USER = gql`
    query GetUser {
      getUser {
        id
        email
        role
        firstName
        lastName
      }
    }
  `;
  const logout = () => {
    setToken(null);
    setUserId(null);
    setUserName(null);
    setUserRole(null);
    localStorage.removeItem('jwtAuth');
  };

  const [setUser] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      const { getUser } = res;
      if (getUser) {
        setUserId(getUser.id);
        setUserRole(getUser.role);
        setUserName(
          getUser.lastName
            ? `${getUser.firstName} ${getUser.lastName}`
            : getUser.firstName,
        );
      }
    },
    onError: err => {
      console.log(err?.graphQLErrors[0]?.message);
    },
  });

  const setUserToken = currentToken => {
    setToken(currentToken);
    localStorage.setItem(
      'jwtAuth',
      JSON.stringify({
        token: currentToken,
      }),
    );
    setUser();
  };

  useEffect(() => {
    const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
    if (jwtAuth) {
      setUserToken(jwtAuth.token);
    }
  }, []);

  return (
    <div className={styles.app}>
      <AuthContext.Provider
        value={{
          token,
          userId,
          userRole,
          userName,
          setUserToken,
          logout,
        }}
      >
        <Router>
          <GlobalStateProvider>
            <Switch>
              <Route exact path={ROUTES.HOME} component={Home} />
              <Route path={ROUTES.FORM} component={FormTour} />
              <Route path={ROUTES.LOGIN} component={Login} />
              <Route path={ROUTES.ADMIN} component={Admin} />
              <Route path={ROUTES.ADMIN_TOUR} component={Admin} />
              <Route
                path={ROUTES.ADMIN_TOURGUIDE_AVAILABLE}
                component={Admin}
              />
              <Route path={ROUTES.ADMIN_TOUR_SPECIAL} component={Admin} />
              <Route path={ROUTES.ADMIN_CALENDAR} component={Admin} />
              <Route path={ROUTES.ADMIN_TOURGUIDE_UPCOMING} component={Admin} />
              {/* <Redirect
                from={ROUTES.ADMIN}
                to={
                  userRole === 'admin'
                    ? ROUTES.ADMIN_TOUR
                    : userRole === 'tourGuide'
                    ? ROUTES.ADMIN_TOURGUIDE_AVAILABLE
                    : null
                }
              /> */}
            </Switch>
          </GlobalStateProvider>
        </Router>
      </AuthContext.Provider>
    </div>
  );
};

export default hot(App);
