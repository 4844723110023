import React, { useState, useEffect, createRef } from 'react';
import cx from 'classnames';

import styles from './Modal.scss';
import SelectTourGuide from './SelectTourGuide';
import ConfirmTourGuide from './ConfirmTourGuide';
import CalendarPreviw from './CalendarPreview';
import PinCode from './PinCode';

const Modal = props => {
  const { children, component, canConfirm, setModalData, modalData } = props;

  const noteFeild = createRef();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    modalData.openModal ? setOpenModal(true) : setOpenModal(false);
  }, [modalData]);
  return (
    <div className={cx(styles.modal, openModal ? styles.open : '')}>
      <section className={styles.modal__content}>
        <div className={styles.modal__content__text}>
          <h2>{children}</h2>
        </div>
        <div className={styles.modal__content__body}>
          {modalData.body === 'deny' && (
            <textarea
              className={styles.modal__content__body__note}
              ref={noteFeild}
              placeholder="Notes"
            />
          )}

          {modalData.body === 'approved' && (
            <SelectTourGuide
              modalData={modalData}
              setOpenModal={setOpenModal}
              setModalData={setModalData}
            />
          )}

          {modalData.body === 'finalize' && (
            <ConfirmTourGuide
              modalData={modalData}
              setOpenModal={setOpenModal}
              setModalData={setModalData}
              booking={modalData.booking}
            />
          )}
          {modalData.body === 'preview' && <CalendarPreviw />}
          {modalData.body === 'pinCode' && <PinCode />}
        </div>

        {modalData.body !== 'approved' && modalData.body !== 'finalize' && (
          <div className={styles.modal__content__actions}>
            <button
              className={cx(styles.btn, styles.btn__close)}
              onClick={() => {
                setOpenModal(false);
                setModalData({ openModa: false });
              }}
            >
              Close
            </button>
            {canConfirm && !modalData.error && (
              <button
                className={cx(styles.btn)}
                onClick={() => {
                  switch (modalData.info) {
                    case 'deny':
                      modalData.onConfirm(
                        noteFeild.current ? noteFeild.current.value : '',
                      );
                      break;
                    default:
                      modalData.onConfirm();
                      break;
                  }
                }}
              >
                Confirm
              </button>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default Modal;
