/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Button, Modal } from '../index';
import styles from './BoxRow.scss';
import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';
import { fetchUrl } from '../../../helpers/fetchUrl';
import * as USERS from '../../../constants/users';

function ToursRowItems(props) {
  const { page, item, timesArray } = props;
  const auth = React.useContext(AuthContext);
  const fetchApi = React.useContext(FetchApiContext);

  const { booking, calendar } = item;
  const { token, userId } = auth;
  const [loading, setLoading] = useState(false);
  const itemStatus = booking.status;
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState();

  const [modalData, setModalData] = useState({
    openModal: false,
    text: '',
    onConfirm: null,
  });

  const openToggle = open ? styles.open : '';

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const approveModal = () => {
    setModalData({
      openModal: true,
      text: 'Select Acceptable Tour Guides',
      body: 'approved',
      onConfirm: approveHandler,
    });
  };

  const approveHandler = tourGuides => {
    const selectedTourGuides = tourGuides.filter(x => x.selected);
    console.log(
      '🚀 ~ file: ToursRowItems.js:49 ~ approveHandler ~ selectedTourGuides',
      selectedTourGuides,
    );

    selectedTourGuides.forEach(tourGuide => {
      const requestBodyUserUpdate = {
        query: `
         mutation{
          assignToursToGuides(input: {
        userId:"${tourGuide.id}",
        tourId: ${booking.tourId},
          })
        }
          `,
      };
      fetch(fetchUrl('graphql'), {
        method: 'POST',
        body: JSON.stringify(requestBodyUserUpdate),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed!');
          }
          return res.json();
        })
        .then(resData => {})
        .catch(err => {
          console.log(err);
        });
    });

    let selectedIdsFlat = '';

    selectedTourGuides.map(({ id }) => {
      selectedIdsFlat += `"${id}"`;
      return false;
    });

    const requestBody = {
      query: `
      mutation{
        updateBookings(updateBookingInput:{tourId:${
          booking.tourId
        }, status:"approved",tourGuides:[${selectedIdsFlat}]}){
          id
          status
          tourId
        }
      }
      `,
    };
    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const denyRequestModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel request',
      body: 'deny',
      info: 'deny',
      onConfirm: denyRequestHandler,
    });
  };

  const denyRequestHandler = note => {
    const requestBody = {
      query: `
      mutation{
        denyBooking(updateBookingInput:{tourId:${
          booking.tourId
        }, status:"denied",note:"${note}"}){
          id
          status
          tourId
        }
      }
      `,
    };

    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const denyApprovedModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel approval',
      onConfirm: denyApprovedHandler,
    });
  };

  const denyApprovedHandler = async () => {
    const requestBody = {
      query: `
      mutation {
        updateBookings(
          updateBookingInput: {
            tourId: ${booking.tourId}
            status: "pending"
            tourGuides: []
          }
        ) {
          id
          status
          tourId
        }
      }
      `,
    };

    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          udpateTourGuides();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });

    function udpateTourGuides() {
      booking.tourGuides
        ? booking.tourGuides.forEach(tourGuide => {
            const requestBodyUserUpdate = {
              query: `
                mutation{
                  removeTourUser(removeTourUserInput: {
                    userId: ${tourGuide},
                    tour : "${booking.tourId}"
                  })
                }
            `,
            };
            fetch(fetchUrl('graphql'), {
              method: 'POST',
              body: JSON.stringify(requestBodyUserUpdate),
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
            })
              .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                  throw new Error('Failed!');
                }
                return res.json();
              })
              .then(resData => {
                if (resData) {
                  // console.log('resData', resData);
                }
              })
              .catch(err => {
                console.log(err);
              });
          })
        : null;
    }
  };

  const finalizeModal = () => {
    setModalData({
      openModal: true,
      text: 'Finalize request',
      body: 'finalize',
      booking,
      onConfirm: finalizeHandler,
    });
  };

  const finalizeHandler = tourGuideSelected => {
    const requestBody = {
      query: `
      mutation{
        updateBookings(updateBookingInput:{tourId:${
          booking.tourId
        },tourGuidesConfirmed:"${tourGuideSelected}", status:"finalized"}){
          id
          status
          tourId
        }
      }
      `,
    };
    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const denyFulfilledModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel finaliazed booking',
      onConfirm: denyFulfilledHandler,
    });
  };

  const denyFulfilledHandler = () => {
    const requestBody = {
      query: `
      mutation{
        updateBookings(updateBookingInput:{tourId:${
          booking.tourId
        },tourGuidesConfirmed:" ", status:"approved"}){
          id
          status
          tourId
        }
      }
      `,
    };
    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const denyDeniedModal = () => {
    setModalData({
      openModal: true,
      text: 'Reset to pending',
      onConfirm: denyDeniedHandler,
    });
  };

  const denyDeniedHandler = () => {
    const requestBody = {
      query: `
      mutation{
        updateBookings(updateBookingInput:{tourId:${
          booking.tourId
        }, status:"pending"}){
          id
          status
          tourId
        }
      }
      `,
    };
    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (calendar && calendar.times) {
      const index = timesArray.findIndex(x => x.id === calendar.times[2]);
      setStartTime(timesArray[index].label);
    }
  }, [item]);

  return (
    <div
      className={cx(
        styles.box__row,
        styles[`box__row--${page}`],
        styles[`box__row--${itemStatus.toLowerCase()}`],
        openToggle,
      )}
    >
      <div
        className={cx(
          styles.box__row__header,
          styles[`box__row__header--${itemStatus.toLowerCase()}`],
          openToggle,
        )}
      >
        <a
          role="button"
          tabIndex={booking.tourId}
          onClick={() => {
            open ? setOpen(false) : setOpen(true);
          }}
        >
          <svg
            width="15"
            height="15"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.arrow}
          >
            <path d="M8.367 11.87l6.267-6.276c.244-.231.366-.52.366-.867 0-.346-.122-.635-.366-.866l-.722-.732a1.18 1.18 0 0 0-.866-.356c-.334 0-.626.119-.877.356L7.5 7.81l-4.68-4.68a1.18 1.18 0 0 0-.866-.356c-.333 0-.625.118-.876.356l-.722.732A1.18 1.18 0 0 0 0 4.727c0 .34.119.63.356.867l6.268 6.277c.25.237.542.356.876.356.34 0 .63-.119.867-.356z" />
          </svg>
        </a>
      </div>
      <div
        className={cx(
          styles.box__row__body,
          styles.box__row__body__3col,
          styles.box__row__body__light,
          itemStatus === 'approved' || itemStatus === 'finalized'
            ? styles.box__row__body__4col
            : '',
          styles[`box__row__body--${itemStatus.toLowerCase()}`],
        )}
      >
        <div className={styles.box__row__body__item}>
          <div>Booking ID</div>
          <div>#{booking.tourId}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Requestor Name</div>
          <div>
            {item.requestorName} ({item.requestorCompany})
          </div>
        </div>

        {(itemStatus === 'approved' || itemStatus === 'finalized') && (
          <div className={styles.box__row__body__item}>
            <div>Tour Guide</div>
            <div>
              {item.tourGuideConfirmed ? (
                item.tourGuideConfirmed.lastName ? (
                  `${item.tourGuideConfirmed.firstName} ${
                    item.tourGuideConfirmed.lastName
                  }`
                ) : (
                  item.tourGuideConfirmed.firstName
                )
              ) : (
                <>
                  {booking.tourGuidesAccepted.length === 0 ? (
                    'No Replies'
                  ) : (
                    <span
                      role="button"
                      tabIndex="0"
                      className={styles.box__row__body__item__link}
                      onClick={() => {
                        finalizeModal();
                      }}
                    >
                      Select Tour Guide
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <div className={styles.box__row__body__item}>
          {calendar ? (
            <>
              <div>Date & Time</div>
              <div>
                {moment.monthsShort(calendar.month - 1)} {calendar.day},{' '}
                {calendar.year} - {startTime}
              </div>
            </>
          ) : (
            <>
              <div>Status</div>
              <div>Denied</div>
            </>
          )}
        </div>
      </div>
      <div
        className={cx(
          styles.box__row__body,
          styles[`box__row__body--${itemStatus.toLowerCase()}`],
        )}
      >
        <div className={styles.box__row__body__item}>
          <div>Requestor Title</div>
          <div>{item.requestorTitle}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Department/Team</div>
          <div>{item.requestorTeam}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Requestor Email</div>
          <div>{item.requestorEmail}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Requestor Phone</div>
          <div>{item.requestorPhone}</div>
        </div>

        <div className={styles.box__row__body__item}>
          <div>Executive Sponsor Name</div>
          <div>{item.sponsorName}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Executive Sponsor Title</div>
          <div>{item.sponsorTitle}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Executive Sponsor Department/Team</div>
          <div>{item.sponsorTeam}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Executive Sponsor Email</div>
          <div>{item.sponsorEmail}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Tour Type</div>
          <div>{toTitleCase(item.tourType)}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Group Name</div>
          <div>{item.tourTypeName}</div>
        </div>

        <div className={styles.box__row__body__item}>
          <div>Number of Attendees</div>
          <div>{item.numAttendees}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Catering</div>
          <div>{toTitleCase(item.catering)}</div>
        </div>
      </div>
      <div
        className={cx(
          styles.box__row__body,
          styles.box__row__body__2col,
          styles.box__row__body__text,
          styles[`box__row__body--${itemStatus.toLowerCase()}`],
        )}
      >
        <div className={styles.box__row__body__item}>
          <div>Purpose of Event</div>
          <div>{item.tourPurpose}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Additional Notes</div>
          <div>{item.notes === '' ? ' ' : item.notes}</div>
        </div>
      </div>
      <div className={styles.box__row__footer}>
        <div className={styles.box__row__footer__item}>
          <div>Date Requested</div>
          <div>{item.createdAt.substring(0, 10).replace(/-/g, ' / ')}</div>
        </div>
        <div className={styles.box__row__footer__item}>
          <div>Status</div>
          <div
            className={cx(
              styles[`box__row__footer__item--${itemStatus.toLowerCase()}`],
            )}
          >
            {toTitleCase(booking.status)}
          </div>
        </div>
        <div className={styles.box__row__footer__item}>
          {itemStatus === 'pending' && (
            <>
              <button
                onClick={() => {
                  denyRequestModal();
                }}
                className={cx(styles.btn, styles['btn--deny'])}
              >
                Deny
              </button>
              <button
                className={cx(styles.btn, styles['btn--approve'])}
                onClick={() => {
                  approveModal();
                }}
              >
                Approve
              </button>
            </>
          )}

          {itemStatus === 'approved' && (
            <>
              <button
                className={cx(styles.btn, styles['btn--finalize'])}
                disabled={booking.tourGuidesAccepted.length === 0}
                onClick={() => {
                  finalizeModal();
                }}
              >
                Finalize
              </button>

              <button
                onClick={() => {
                  denyApprovedModal();
                }}
                className={cx(styles.btn, styles['btn--deny'])}
              >
                Reset
              </button>
            </>
          )}

          {itemStatus === 'finalized' && (
            <button
              onClick={() => {
                denyFulfilledModal();
              }}
              className={cx(styles.btn, styles['btn--deny'])}
            >
              Reset
            </button>
          )}

          {/* {itemStatus === 'denied' && (
            <button
              onClick={() => {
                denyDeniedModal();
              }}
              className={cx(styles.btn, styles['btn--deny'])}
            >
              Reset
            </button>
          )} */}
        </div>
      </div>

      <Modal canConfirm setModalData={setModalData} modalData={modalData}>
        {modalData.text}
      </Modal>
    </div>
  );
}

export default ToursRowItems;
