import React from 'react';

import { NavLink } from '../shared';

import styles from './Header.scss';
import * as ROUTES from '../../constants/routes';
import * as USERS from '../../constants/users';

const Nav = props => {
  const { auth, location } = props;
  return (
    <nav className={styles.nav}>
      <ul>
        {(auth.userRole === 'admin' || auth.userRole === 'manager') && (
          <>
            <li>
              <NavLink to={ROUTES.ADMIN_TOUR} {...props}>
                Tours
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.ADMIN_TOUR_SPECIAL} {...props}>
                Special Events
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.ADMIN_CALENDAR} {...props}>
                Calendar
              </NavLink>
            </li>
          </>
        )}
        {auth.userRole === 'tourGuide' && (
          <>
            <li>
              <NavLink to={ROUTES.ADMIN_TOURGUIDE_AVAILABLE} {...props}>
                Available Tours
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.ADMIN_TOURGUIDE_UPCOMING} {...props}>
                Upcoming Tours
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Nav;
