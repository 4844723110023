import React, { useState, useEffect, createRef, useRef } from 'react';
import { useForm } from 'react-hooks-helper';
import cx from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Button, Input } from '../../components/shared';
import { AuthContext } from '../../contexts/AuthContext';
import * as ROUTES from '../../constants/routes';
import * as USERS from '../../constants/users';
import { fetchUrl } from '../../helpers/fetchUrl';

import styles from '../Forms/Forms.scss';
import stylesForm from '../../components/shared/Form/Form.scss';

import logo from '../../images/logo.png';

const LOGIN = gql`
  mutation Login($input: loginUserInput) {
    login(input: $input) {
      token
    }
  }
`;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function validateEmail(email) {
  const validate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate.test(email);
}

function validatePass(pass) {
  return pass.length >= 6;
}

const LoginData = {
  email: process.env.NODE_ENV === 'development' ? 'admin@hp.com' : '',
  password: process.env.NODE_ENV === 'development' ? 'testAdmin' : '',
};

const Login = props => {
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState({ err: '' });
  const [passError, setPassError] = useState({ err: '' });
  const [loginError, setLoginError] = useState();
  const [formData, setForm] = useForm(LoginData);
  const { auth, history } = props;
  const { email, password } = formData;
  const [loginHandler, { data, loading, error: mutationError }] = useMutation(
    LOGIN,
  );

  const emailFeild = createRef();
  const passFeild = createRef();

  const prevEmail = usePrevious(email);
  const prevPass = usePrevious(password);

  function emailOnFocus() {
    if (email !== '' && !validateEmail(email)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else {
      setEmailError({ err: '' });
    }
  }

  function passOnFocus() {
    if (password !== '' && !validatePass(password)) {
      setPassError({ err: 'Password is not Valid! Minimum 8 characters.' });
    } else {
      setPassError({ err: '' });
    }
  }

  const handleSubmit = () => {
    if (!email || !password) return;
    loginHandler({
      variables: {
        input: {
          email,
          password,
        },
      },
    });
  };

  useEffect(() => {
    if (
      email !== '' &&
      password !== '' &&
      validateEmail(email) &&
      validatePass(password)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (prevEmail && prevEmail.length > email.length && !validateEmail(email)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else if (validateEmail(email)) {
      setEmailError({ err: '' });
    }

    if (validateEmail(email)) {
      setEmailError({ err: '' });
    }

    if (
      prevPass &&
      prevPass.length > password.length &&
      !validatePass(password)
    ) {
      setPassError({ err: 'Password is not Valid! Minimum 8 characters.' });
    } else {
      setPassError({ err: '' });
    }

    if (loginError === 'User does not exist!' && !auth.token) {
      setEmailError({ err: 'User does not exist!' });
    } else {
      setEmailError({ err: '' });
    }

    if (!auth.token && !validatePass(password)) {
      setPassError({ err: 'Password is invalid!' });
    }

    if (loginError === 'Password is invalid!') {
      setPassError({ err: 'Password is invalid!' });
    } else {
      setPassError({ err: '' });
    }
    emailFeild.current &&
      emailFeild.current.addEventListener('focusout', emailOnFocus);
    passFeild.current &&
      passFeild.current.addEventListener('focusout', passOnFocus);

    return () => {
      emailFeild.current
        ? emailFeild.current.removeEventListener('focusout', emailOnFocus)
        : null;
      passFeild.current
        ? passFeild.current.removeEventListener('focusout', passOnFocus)
        : null;
    };
  }, [formData, isValid, auth, loginError]);
  useEffect(() => {
    if (mutationError) {
      if (mutationError?.graphQLErrors[0]?.message === 'User does not exist!') {
        setLoginError('User does not exist!');
      }
      if (mutationError?.graphQLErrors[0]?.message === 'Invalid password') {
        setLoginError('Password is invalid!');
      }
    } else {
      setLoginError('');
    }
    if (data) {
      const {
        login: { token },
      } = data;
      if (token) {
        auth.setUserToken(token);
        history.push(ROUTES.ADMIN);
      }
    }
  }, [data, mutationError, loading]);

  return (
    <div className={styles.forms}>
      <div className={styles.forms__header}>
        <div className={styles.forms__header__logo}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.forms__header__content}>
          <h1>Login</h1>
          <h5>Please login to continue</h5>
        </div>
      </div>
      <div className={styles.forms__content}>
        <div className={styles.form}>
          <h4>Your Email Address *</h4>
          <label
            htmlFor="email"
            className={cx(
              stylesForm.input,
              emailError.err !== '' ? stylesForm.input__email_error : '',
            )}
          >
            <input
              type="text"
              placeholder=" "
              id="email"
              name="email"
              value={email}
              onChange={setForm}
              ref={emailFeild}
              autoComplete="off"
            />
            <span className={stylesForm.label}>Type your email here …</span>
            <span className={stylesForm.border} />
            {emailError.err !== '' && (
              <span className={stylesForm.email__error}>{emailError.err}</span>
            )}
          </label>

          <h4>Password *</h4>

          <label
            htmlFor="password"
            className={cx(
              stylesForm.input,
              passError.err !== '' ? stylesForm.input__email_error : '',
            )}
          >
            <input
              type="password"
              placeholder=" "
              id="password"
              name="password"
              value={password}
              onChange={setForm}
              ref={passFeild}
              autoComplete="off"
            />
            <span className={stylesForm.label}>Type your password here …</span>
            <span className={stylesForm.border} />
            {passError.err !== '' && (
              <span className={stylesForm.email__error}>{passError.err}</span>
            )}
          </label>
          <div className={styles.form__navigation}>
            <button onClick={() => handleSubmit()} disabled={!isValid}>
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <AuthContext.Consumer>
    {auth => <Login {...props} auth={auth} ref={ref} />}
  </AuthContext.Consumer>
));
