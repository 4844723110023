/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';
import Holidays from 'date-holidays';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loader from 'react-loader-spinner';

import { Navigation, KeyboardNav } from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import Calendar from '../../../../../components/Calendar/index';

const hd = new Holidays('US');

const GET_TIMES = gql`
  query Calendar($date: CalendarInput!) {
    getCalendar(calendarInput: $date) {
      id
      times
    }
  }
`;

const DateTime = props => {
  const { dateTime, setDateTime, navigation, timesArray, step, index } = props;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(true);
  const [timeLoading, setTimeLoading] = useState(true);
  const [times, setTimes] = useState();

  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [selectedDay, setSelectedDay] = useState(moment().date() + 1);

  const [pickedTime, setPickedTime] = useState();

  const [
    getTimes,
    { loading: getTimesLoading, error, data: timesInfo },
  ] = useLazyQuery(GET_TIMES, {
    variables: {
      date: { year: selectedYear, month: selectedMonth, day: selectedDay },
    },
    fetchPolicy: 'network-only',
    onCompleted: res => {
      updateTimes(res.getCalendar);
    },
    onError: err => {
      console.log(err);
    },
  });

  const pickTime = id => {
    const bufferArray = [id - 2, id - 1, id, id + 1, id + 2, id + 3, id + 4];
    setDateTime({
      year: selectedYear,
      month: selectedMonth,
      day: selectedDay,
      times: bufferArray,
    });
    setPickedTime(id);

    getTimes();
  };

  const updateTimes = async res => {
    const saturday = await moment(`${selectedYear}-${selectedMonth}`, 'YYYY-MM')
      .startOf('month')
      .day('Saturday');
    const sunday = await moment(`${selectedYear}-${selectedMonth}`, 'YYYY-MM')
      .startOf('month')
      .day('Sunday');

    const weekendsInMonth = [];
    if (saturday.date() > 7) saturday.add(7, 'd');
    while (selectedMonth - 1 === saturday.month()) {
      weekendsInMonth.push(saturday.date());
      saturday.add(7, 'd');
    }

    if (sunday.date() > 7) sunday.add(7, 'd');
    while (selectedMonth - 1 === sunday.month()) {
      weekendsInMonth.push(sunday.date());
      sunday.add(7, 'd');
    }

    const bookedIds = [];
    const updatedTimes = [];

    if (res)
      await res.forEach(booking => {
        booking.times ? booking.times.map(time => bookedIds.push(time)) : null;
      });

    const isHoliday = hd.isHoliday(
      new Date(
        `${selectedYear}-${selectedMonth}-${selectedDay} 10:00:00 GMT+0500`,
      ),
    );
    await timesArray.forEach(time => {
      const isBooked = bookedIds.includes(time.id);
      const isWeekend = weekendsInMonth.includes(selectedDay);
      const holidayWeekendLabel = isWeekend
        ? 'Weekend'
        : isHoliday
        ? isHoliday.name
        : null;
      const noBuffer = isBooked
        ? false
        : bookedIds.includes(time.id + 1) || bookedIds.includes(time.id + 2);

      updatedTimes.push(
        <button
          key={time.label}
          disabled={isBooked || noBuffer}
          onClick={() => pickTime(time.id)}
          // label={holidayWeekendLabel}
          className={cx(
            !isHoliday && !isHoliday && noBuffer ? styles.no__buffer : '',
            time.id === pickedTime ? styles.selected : '',
            isWeekend || isHoliday ? styles.holiday : '',
          )}
        >
          {time.label}
        </button>,
      );
    });

    setTimes(updatedTimes);
  };

  const isBefore = (y, m) => {
    const selectedDate = moment([y, m]);
    const todayDate = moment([moment().year(), moment().month()]);

    const beforeYear = todayDate.isAfter(selectedDate, 'years');
    const beforeMonth = selectedDate.isBefore(todayDate, 'months');
    return beforeMonth || beforeYear;
  };

  const isThisMonth = (y, m) => {
    const selectedDate = moment([y, m]);
    const todayDate = moment([moment().year(), moment().month() + 1]);
    const isSameYear = todayDate.isSame(selectedDate, 'years');
    const isSameMonth = selectedDate.isSame(todayDate, 'months');
    return isSameYear && isSameMonth;
  };

  const changeDateHandler = dir => {
    setPickedTime(null);

    const isPast = isBefore(selectedYear, selectedMonth - 2);
    const thisMonth = isThisMonth(selectedYear, selectedMonth - 1);
    if (dir === 'next') {
      if (selectedMonth === 12) {
        setSelectedYear(selectedYear + 1);
        setSelectedMonth(1);
      } else {
        setSelectedMonth(selectedMonth + 1);
      }
      setSelectedDay(1);
      getTimes();
    } else if (dir === 'prev') {
      if (!isPast) {
        if (thisMonth) {
          setSelectedDay(moment().date() + 1);
        } else {
          setSelectedDay(1);
        }
      }
      if (selectedMonth === 1) {
        setSelectedYear(selectedYear - 1);
        setSelectedMonth(12);
        getTimes();
      } else if (!isPast) {
        setSelectedMonth(selectedMonth - 1);
        getTimes();
      }
    }
  };

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (!timesInfo) {
      getTimes();
    }

    !getTimesLoading
      ? setTimeout(() => {
          setTimeLoading(false);
        }, 1000)
      : setTimeLoading(true);
    if (dateTime && !timeLoading) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [
    getTimesLoading,
    dateTime,
    selectedDay,
    selectedMonth,
    selectedYear,
    times,
    pickedTime,
    isValid,
    timeLoading,
  ]);
  return (
    <>
      <h4>
        <span>{index + 1}</span> Select Date and Start Time?
      </h4>
      {/* <h5>Can’t find a time that works? Contact us</h5> */}
      <div className={styles.calendar__wrapper}>
        <div className={styles.calendar__cal}>
          <div className={styles.calendar__cal__nav}>
            <a
              role="button"
              tabIndex="0"
              onClick={() => changeDateHandler('prev')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 451.846 451.847"
              >
                <path d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z" />
              </svg>
            </a>
            <a
              role="button"
              tabIndex="0"
              onClick={() => changeDateHandler('next')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 451.846 451.847"
              >
                <path d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z" />
              </svg>
            </a>
          </div>
          <Calendar
            year={selectedYear}
            month={selectedMonth}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
          />
        </div>
        <div className={styles.calendar__time}>
          {timeLoading ? (
            <div className={styles.calendar__time__wrapper__loader}>
              <Loader type="Grid" color="#fff" height={30} width={30} />
            </div>
          ) : (
            <>
              <div className={styles.calendar__time__wrapper}>
                {times && times.map(time => time)}
              </div>
              <p>- Note that tours are approximatly 45 minutes in duration.</p>
              <div className={styles.calendar__time__guide}>
                <div className={styles.available}>
                  <span />
                  <span>Available</span>
                </div>
                <div className={styles.not_available}>
                  <span />
                  <span>Not Available</span>
                </div>
                <div className={styles.booked}>
                  <span />
                  <span>Booked/Closed</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Navigation navigation={navigation} isValid={isValid} goHome />
    </>
  );
};

export default DateTime;
