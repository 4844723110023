import React from 'react';
import cx from 'classnames';

import styles from './Form.scss';

const Input = ({
  placeholder,
  name,
  selectitem,
  half,
  children,
  label,
  className,
  type = 'text',
  ...otherProps
}) => {
  return (
    <>
      {type === 'text' || type === 'date' ? (
        <div
          className={cx(
            styles.input__wrapper,
            half ? styles.half : null,
            label ? styles.input__one : null,
            className,
          )}
        >
          <label htmlFor={name} className={cx(styles.input)}>
            <input
              type={type}
              id={name}
              name={name}
              placeholder=" "
              {...otherProps}
              autoComplete="nope"
            />
            <span className={styles.label}>{placeholder}</span>
            <span className={styles.border} />
          </label>
        </div>
      ) : (
        <div className={cx(styles.input__wrapper, half ? styles.half : null)}>
          <label
            htmlFor={name}
            className={cx(
              styles.input,

              styles.input__textarea,
            )}
          >
            <textarea
              type={type}
              id={name}
              name={name}
              placeholder=" "
              {...otherProps}
              autoComplete="nope"
            />
            <span className={styles.label}>{placeholder}</span>
            <span className={styles.border} />
          </label>
        </div>
      )}
    </>
  );
};

export default Input;
