import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { AuthContext } from '../contexts/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = React.useContext(AuthContext);

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => {
    console.log('auth.token', auth);
    setTimeout(() => {
      if (auth) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    }, 400);
  }, [auth]);
  console.log('here', isAuthenticated);

  if (isAuthenticated === null) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <Redirect to={ROUTES.LOGIN} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
