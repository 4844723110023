/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Loader from 'react-loader-spinner';

import { BoxRow } from '..';

import styles from './BoxContainer.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function BoxContainer(props) {
  const { section, items, clickPaginationHandler } = props;
  const { data, pageInfo, offset, limit } = items;
  const [loading, setLoading] = useState(true);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const firstItemNum = limit * offset + 1;
  const lastItemNum = firstItemNum + limit - 1;

  useEffect(() => {
    setLoading(true);
    if (data) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [data]);

  const loaderColor =
    section === 'pending'
      ? '#fec700'
      : section === 'approved'
      ? '#52ffa7'
      : section === 'finalized'
      ? '#36e5ff'
      : section === 'denied'
      ? '#ec736d'
      : section === 'available'
      ? '#fec700'
      : section === 'upcoming'
      ? '#36e5ff'
      : '';

  return (
    <div className={styles.box_container}>
      <div className={cx(styles.header, styles[`header--${section}`])}>
        <h4 className={styles.header__title}>{toTitleCase(section)}</h4>
        <div className={styles.header__separator} />
      </div>
      <div
        className={cx(
          styles.box_container__content,
          loading ? styles.loading : '',
        )}
      >
        {!loading && pageInfo ? (
          <>
            {pageInfo.allItems === 0 ? (
              <p className={styles.no_tours}>No Items</p>
            ) : (
              <>
                {data.map(item => {
                  return <BoxRow item={item} key={item.id} />;
                })}
                <div
                  className={cx(
                    styles.pagination,
                    styles[`pagination--${section}`],
                  )}
                >
                  <div>
                    <p>
                      Showing{' '}
                      {firstItemNum > pageInfo.allItems
                        ? pageInfo.allItems
                        : firstItemNum}{' '}
                      to{' '}
                      {lastItemNum > pageInfo.allItems
                        ? pageInfo.allItems
                        : lastItemNum}{' '}
                      of {pageInfo.allItems} entries
                    </p>
                  </div>
                  <div className={styles.pagination__btn}>
                    <button
                      onClick={() => {
                        clickPaginationHandler(section, 'prev');
                        setLoading(true);
                      }}
                      disabled={!pageInfo.hasPrevPage}
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => {
                        clickPaginationHandler(section, 'next');
                        setLoading(true);
                      }}
                      disabled={!pageInfo.hasNextPage}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className={styles.loader}>
            <Loader type="Grid" color={loaderColor} height={40} width={40} />
          </div>
        )}
      </div>
    </div>
  );
}

export default BoxContainer;
