import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';
import { pageContext } from '../../../contexts/PageContext';
import * as ROUTES from '../../../constants/routes';
import * as USERS from '../../../constants/users';
import { fetchUrl } from '../../../helpers/fetchUrl';
import { exportCsv } from '../../../helpers/exportCsv';
import { toProperCase } from '../../../helpers/toProperCase';

import { BoxRow, BoxContainer } from '../../../components/shared';
import Dropdown from '../../../components/shared/Form/Dropdown';

import styles from '../AdminTour/Tour.scss';

const usePreviousValue = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const ALL_TOURS_TYPES = gql`
  query AllToursTypes($page: Page!) {
    allToursTypes(page: $page) {
      tours {
        id
        requestorName
        requestorTitle
        requestorTeam
        requestorEmail
        requestorPhone
        requestorCompany
        sponsorName
        sponsorTitle
        sponsorTeam
        sponsorEmail
        tourType
        tourTypeName
        tourPurpose
        numAttendees
        catering
        tourContact
        tourContactName
        tourContactEmail
        tourContactPhone
        tourNeeded
        notes
        type
        createdAt
        booking {
          id
          tourId
          status
          tourGuides
          tourGuidesAccepted
          tourGuidesConfirmed
        }
        tourGuideConfirmed {
          firstName
          lastName
        }
        calendar {
          id
          year
          month
          day
          times
        }
      }
      pageInfo {
        hasNextPage
        hasPrevPage
        allItems
      }
    }
  }
`;

const AdminTour = props => {
  const { history } = props;
  const auth = React.useContext(AuthContext);

  const tourTypeFilter = [
    ['allTourTypes', '- All Request Types -'],
    ['internal', 'Internal'],
    ['customer', 'Customer'],
    ['outreach', 'Outreach'],
  ];

  const [exportUrl, setExportUrl] = useState();
  const [allTours, setAllTours] = useState({ offset: 0, limit: 16 });
  const [tourTypeSelected, setTourTypeSelected] = useState(
    tourTypeFilter[0][0],
  );
  const [userId, setUserId] = useState();
  const prevUserValue = usePreviousValue(userId);
  const [allToursFetch] = useLazyQuery(ALL_TOURS_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setAllTours({
        ...allTours,
        data: res.allToursTypes.tours,
        pageInfo: {
          hasNextPage: res.allToursTypes.pageInfo.hasNextPage,
          hasPrevPage: res.allToursTypes.pageInfo.hasPrevPage,
          allItems: res.allToursTypes.pageInfo.allItems,
        },
      });
    },
    onError: err => {
      console.log(err);
    },
  });

  const filterArray = value => {
    fetchApiHandler(value);
  };

  const fetchApiHandler = (tourType = tourTypeFilter[0][0]) => {
    setTourTypeSelected(tourTypeSelected);
    setAllTours({
      ...allTours,
      offset: 0,
    });

    allToursFetch({
      variables: {
        page: {
          offset: allTours.offset,
          type: 'all',
          tourType,
          status: 'approved',
          userId: auth.userId,
          limit: allTours.limit,
        },
      },
    });
  };

  const clickPaginationHandler = (section, dir) => {
    if (section === 'Available') {
      allToursFetch({
        variables: {
          page: {
            offset: dir === 'next' ? allTours.offset + 1 : allTours.offset - 1,
            type: 'all',
            tourType: tourTypeSelected,
            status: 'approved',
            limit: allTours.limit,
          },
        },
      });
      setAllTours({
        ...allTours,
        offset: dir === 'next' ? allTours.offset + 1 : allTours.offset - 1,
      });
    }
  };

  useEffect(() => {
    userId && prevUserValue !== userId ? fetchApiHandler() : null;
    allTours ? exportCsv(allTours, setExportUrl) : null;
    if (!auth.token) {
      const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
      !jwtAuth ? history.push(ROUTES.LOGIN) : null;
    } else {
      setUserId(auth.userId);
    }
  }, [auth, userId, allTours]);

  return (
    <div className={styles.main}>
      <div className={styles.body}>
        <div className={styles.filter}>
          <div className={styles.filter__type}>
            <div className={styles.filter__type__item}>
              <Dropdown
                onChange={item => filterArray(item.target.value)}
                options={tourTypeFilter}
              />
            </div>
          </div>
          <button
            className={styles.btn__filter}
            onClick={() => fetchApiHandler()}
          >
            Refresh
          </button>
          <a
            href={exportUrl}
            download={`tours-${new Date().getFullYear()}-${new Date().getMonth() +
              1}-${new Date().getDate()}-${new Date().getHours()}_${new Date().getMinutes()}.csv`}
          >
            <button className={styles.btn__filter}>Export</button>
          </a>
        </div>
        <pageContext.Provider value="tourGuides">
          <FetchApiContext.Provider value={fetchApiHandler}>
            {allTours && (
              <BoxContainer
                clickPaginationHandler={clickPaginationHandler}
                items={allTours}
                section="available"
              />
            )}
          </FetchApiContext.Provider>
        </pageContext.Provider>
      </div>
    </div>
  );
};

export default AdminTour;
