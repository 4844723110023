/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

export const tourTypeList = [
  {
    id: 'internal',
    label:
      'Internal - Executive leadership hosted visit for teams, interns, new employees etc.',
  },
  {
    id: 'customer',
    label: 'Customer - Hosted visit for current or prospective customers',
  },
  {
    id: 'outreach',
    label:
      'Outreach - Use for recruitment, non-profits, education, community efforts and media',
  },
];

const TypeOfTour = ({ setForm, formData, navigation, step, index }) => {
  const { tourType, tourTypeName } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (tourType !== '' && tourTypeName !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <div>
      <h4>
        <span>{index + 1}</span> Type of Tour *
      </h4>

      <div className={cx(styles.form__multiple, styles.form__multiple__col1)}>
        {tourTypeList.map(type => {
          const { id, label } = type;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="tourType"
              value={id}
              checked={tourType === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
      </div>

      {tourType !== '' && (
        <Input
          name="tourTypeName"
          placeholder={
            tourType === 'internal'
              ? 'What is the name of the visiting group? *'
              : tourType === 'customer'
              ? 'Who is the Customer? *'
              : tourType === 'outreach'
              ? 'How would you categorize the outreach? Charity, Education, Media etc? *'
              : null
          }
          value={tourTypeName}
          onChange={setForm}
          label
        />
      )}
      <Navigation navigation={navigation} isValid={isValid} />
    </div>
  );
};

export default TypeOfTour;
