import React, { useState, useEffect } from 'react';
import MultiStepTourForm from './MultiStepTourForm';
import MultiStepEventForm from './MultiStepEventForm';
import { useGlobalState } from '../../../contexts/globalState';
import * as ROUTES from '../../../constants/routes';

import logo from '../../../images/logo.png';
import { Button, Radio } from '../../../components/shared';

import styles from '../Forms.scss';

const typeOfEvent = [
  { id: 'tour', label: 'Tour' },
  {
    id: 'event',
    label: 'Special Event',
  },
];

const FormTour = props => {
  const [eventType, setEventType] = useState();
  const [formToLoad, setFormToLoad] = useState(false);
  const [pinCode, setPinCode] = useState();
  const [userEmail, setUserEmail] = useState();
  const [precent] = useGlobalState('percent');
  const [steps] = useGlobalState('steps');
  const [currentStep] = useGlobalState('currentStep');

  const percentWidth = { width: `${precent}%` };
  const handleChange = e => {
    setEventType(e.target.value);
  };
  useEffect(() => {
    // const ssoUserEmail = JSON.parse(localStorage.getItem('SSOUSEREMAIL'));
    // if (!ssoUserEmail) {
    //   props.history.push(ROUTES.HOME);
    // }
    const checkSessionCookie = () => {
      const cookies = document.cookie.split('; ');
      let hasPHPSessionCookie = false;
      let hasSSOUserEmailCookie = false;

      cookies.forEach(cookie => {
        const [name, value] = cookie.split('=');
        if (name.trim() === 'PHPSESSID') {
          hasPHPSessionCookie = true;
        }
        if (name.trim() === 'SSOUSEREMAIL') {
          hasSSOUserEmailCookie = true;
          // console.log('🚀 ~ checkSessionCookie ~ value:', value);
          setUserEmail(decodeURIComponent(value));
        }
      });

      if (!hasPHPSessionCookie || !hasSSOUserEmailCookie) {
        console.log('Session cookie(s) not found!');
        if (process.env.NODE_ENV !== 'development') {
          props.history.push(ROUTES.HOME);
        }
      } else {
        // console.log('Session cookie(s) found.', userEmail);
      }
    };

    checkSessionCookie();
  }, [currentStep, eventType, userEmail]);

  return (
    <div className={styles.forms}>
      <div className={styles.forms__header}>
        <div className={styles.forms__header__logo}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.forms__header__content}>
          <span style={{ padding: '0 10px' }}>{userEmail}</span>
          <Button
            btnType="blue"
            btnSize="small"
            className={styles.btn}
            href="mailto:addisonave@heritagewerks.com"
            external
          >
            Contact
          </Button>
        </div>
      </div>
      <div className={styles.forms__content}>
        {formToLoad &&
          (eventType === 'tour' ? (
            <MultiStepTourForm />
          ) : (
            <MultiStepEventForm />
          ))}
        {!formToLoad && (
          <>
            <h4>
              <span>1</span> Type of Event? *
            </h4>

            <div className={styles.radio__buttons}>
              {typeOfEvent.map(form => {
                const { id, label } = form;

                return (
                  <Radio
                    id={id}
                    key={id}
                    label={label}
                    name="typeOfEvent"
                    value={id}
                    checked={eventType === id}
                    onChange={handleChange}
                    type="radio"
                  />
                );
              })}
            </div>
            <Button
              btnType="blue"
              className={styles.btn}
              disabled={!eventType}
              onClick={() => {
                setFormToLoad(eventType);
              }}
            >
              Next
            </Button>
          </>
        )}
      </div>
      {currentStep !== 0 && currentStep <= steps && (
        <div className={styles.forms__percentage}>
          <span className={styles.number}>
            {currentStep}/{steps} Complete
          </span>
          <span className={styles.percent}>
            <span style={percentWidth} />
          </span>
        </div>
      )}
    </div>
  );
};

export default FormTour;
