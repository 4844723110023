import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';
import { pageContext } from '../../../contexts/PageContext';
import * as ROUTES from '../../../constants/routes';
import * as USERS from '../../../constants/users';
import { fetchUrl } from '../../../helpers/fetchUrl';
import { exportCsv } from '../../../helpers/exportCsv';
import { toProperCase } from '../../../helpers/toProperCase';

import { BoxRow, BoxContainer } from '../../../components/shared';
import Dropdown from '../../../components/shared/Form/Dropdown';

import styles from './Tour.scss';

const ALL_TOURS_TYPES = gql`
  query AllToursTypes($page: Page!) {
    allToursTypes(page: $page) {
      tours {
        id
        requestorName
        requestorTitle
        requestorTeam
        requestorEmail
        requestorPhone
        requestorCompany
        sponsorName
        sponsorTitle
        sponsorTeam
        sponsorEmail
        tourType
        tourTypeName
        tourPurpose
        numAttendees
        catering
        tourContact
        tourContactName
        tourContactEmail
        tourContactPhone
        tourNeeded
        notes
        createdAt
        booking {
          id
          tourId
          status
          tourGuides
          tourGuidesAccepted
          tourGuidesConfirmed
        }
        tourGuideConfirmed {
          firstName
          lastName
        }
        calendar {
          id
          year
          month
          day
          times
        }
      }
      pageInfo {
        hasNextPage
        hasPrevPage
        allItems
      }
    }
  }
`;

const AdminEvent = props => {
  const { history } = props;
  const auth = React.useContext(AuthContext);

  const tourTypeFilter = [
    ['allTourTypes', '- All Request Types -'],
    ['internal', 'Internal'],
    ['customer', 'Customer'],
    ['outreach', 'Outreach'],
  ];

  const [exportUrl, setExportUrl] = useState();
  const [pending, setPending] = useState({ offset: 0, limit: 8 });
  const [approved, setApproved] = useState({ offset: 0, limit: 8 });
  const [finalized, setFinalized] = useState({ offset: 0, limit: 8 });
  const [denied, setDenied] = useState({ offset: 0, limit: 8 });
  const [allTours, setAllTours] = useState();
  const [tourTypeSelected, setTourTypeSelected] = useState(
    tourTypeFilter[0][0],
  );

  const [allToursFetch] = useLazyQuery(ALL_TOURS_TYPES, {
    variables: {
      page: {
        offset: 0,
        type: 'event',
        tourType: tourTypeFilter[0][0],
        status: 'all',
        limit: 1000,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setAllTours(res.allToursTypes.tours);
    },
    onError: err => {
      console.log(err);
    },
  });

  const [pendingTours] = useLazyQuery(ALL_TOURS_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setPending({
        ...pending,
        data: res.allToursTypes.tours,
        pageInfo: {
          hasNextPage: res.allToursTypes.pageInfo.hasNextPage,
          hasPrevPage: res.allToursTypes.pageInfo.hasPrevPage,
          allItems: res.allToursTypes.pageInfo.allItems,
        },
      });
    },
    onError: err => {
      console.log(err);
    },
  });

  const [approvedTours] = useLazyQuery(ALL_TOURS_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setApproved({
        ...approved,
        data: res.allToursTypes.tours,
        pageInfo: {
          hasNextPage: res.allToursTypes.pageInfo.hasNextPage,
          hasPrevPage: res.allToursTypes.pageInfo.hasPrevPage,
          allItems: res.allToursTypes.pageInfo.allItems,
        },
      });
    },
    onError: err => {
      console.log(err);
    },
  });

  const [finalizedTours] = useLazyQuery(ALL_TOURS_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setFinalized({
        ...finalized,
        data: res.allToursTypes.tours,
        pageInfo: {
          hasNextPage: res.allToursTypes.pageInfo.hasNextPage,
          hasPrevPage: res.allToursTypes.pageInfo.hasPrevPage,
          allItems: res.allToursTypes.pageInfo.allItems,
        },
      });
    },
    onError: err => {
      console.log(err);
    },
  });

  const [deniedTours] = useLazyQuery(ALL_TOURS_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setDenied({
        ...denied,
        data: res.allToursTypes.tours,
        pageInfo: {
          hasNextPage: res.allToursTypes.pageInfo.hasNextPage,
          hasPrevPage: res.allToursTypes.pageInfo.hasPrevPage,
          allItems: res.allToursTypes.pageInfo.allItems,
        },
      });
    },
    onError: err => {
      console.log(err);
    },
  });

  const filterArray = value => {
    fetchApiHandler(value);
  };

  const fetchApiHandler = (tourType = tourTypeFilter[0][0]) => {
    setTourTypeSelected(tourTypeSelected);
    setPending({
      ...pending,
      offset: 0,
    });
    setApproved({
      ...approved,
      offset: 0,
    });
    setFinalized({
      ...finalized,
      offset: 0,
    });
    setDenied({
      ...denied,
      offset: 0,
    });
    pendingTours({
      variables: {
        page: {
          offset: 0,
          type: 'event',
          tourType,
          status: 'pending',
          limit: pending.limit,
        },
      },
    });
    approvedTours({
      variables: {
        page: {
          offset: 0,
          type: 'event',
          tourType,
          status: 'approved',
          limit: approved.limit,
        },
      },
    });
    finalizedTours({
      variables: {
        page: {
          offset: 0,
          type: 'event',
          tourType,
          status: 'finalized',
          limit: finalized.limit,
        },
      },
    });
    deniedTours({
      variables: {
        page: {
          offset: 0,
          type: 'event',
          tourType,
          status: 'denied',
          limit: denied.limit,
        },
      },
    });
  };

  const clickPaginationHandler = (section, dir) => {
    if (section === 'pending') {
      pendingTours({
        variables: {
          page: {
            offset: dir === 'next' ? pending.offset + 1 : pending.offset - 1,
            type: 'event',
            tourType: tourTypeSelected,
            status: 'pending',
            limit: pending.limit,
          },
        },
      });
      setPending({
        ...pending,
        offset: dir === 'next' ? pending.offset + 1 : pending.offset - 1,
      });
    } else if (section === 'approved') {
      approvedTours({
        variables: {
          page: {
            offset: dir === 'next' ? approved.offset + 1 : approved.offset - 1,
            type: 'event',
            status: 'approved',
            limit: approved.limit,
          },
        },
      });
      setApproved({
        ...approved,
        offset: dir === 'next' ? approved.offset + 1 : approved.offset - 1,
      });
    } else if (section === 'finalized') {
      finalizedTours({
        variables: {
          page: {
            offset:
              dir === 'next' ? finalized.offset + 1 : finalized.offset - 1,
            type: 'event',
            status: 'finalized',
            limit: finalized.limit,
          },
        },
      });
      setFinalized({
        ...finalized,
        offset: dir === 'next' ? finalized.offset + 1 : finalized.offset - 1,
      });
    } else if (section === 'denied') {
      deniedTours({
        variables: {
          page: {
            offset: dir === 'next' ? denied.offset + 1 : denied.offset - 1,
            type: 'event',
            status: 'denied',
            limit: denied.limit,
          },
        },
      });
      setDenied({
        ...denied,
        offset: dir === 'next' ? denied.offset + 1 : denied.offset - 1,
      });
    }
  };

  useEffect(() => {
    fetchApiHandler();
    allToursFetch();
  }, []);

  useEffect(() => {
    allTours ? exportCsv(allTours, setExportUrl) : null;
    if (!auth.token) {
      const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
      !jwtAuth ? history.push(ROUTES.LOGIN) : null;
    }
  }, [auth, pending, approved, finalized, denied]);

  return (
    <div className={styles.main}>
      <div className={styles.body}>
        <div className={styles.filter}>
          <div className={styles.filter__type}>
            <div className={styles.filter__type__item}>
              <Dropdown
                onChange={item => filterArray(item.target.value)}
                options={tourTypeFilter}
              />
            </div>
          </div>
          <button
            className={styles.btn__filter}
            onClick={() => fetchApiHandler()}
          >
            Refresh
          </button>
          <a
            href={exportUrl}
            download={`tours-${new Date().getFullYear()}-${new Date().getMonth() +
              1}-${new Date().getDate()}-${new Date().getHours()}_${new Date().getMinutes()}.csv`}
          >
            <button className={styles.btn__filter}>Export</button>
          </a>
        </div>
        <pageContext.Provider value="events">
          <FetchApiContext.Provider value={fetchApiHandler}>
            {pending && (
              <BoxContainer
                clickPaginationHandler={clickPaginationHandler}
                items={pending}
                section="pending"
              />
            )}
            {approved && (
              <BoxContainer
                clickPaginationHandler={clickPaginationHandler}
                items={approved}
                section="approved"
              />
            )}
            {finalized && (
              <BoxContainer
                clickPaginationHandler={clickPaginationHandler}
                items={finalized}
                section="finalized"
              />
            )}
            {denied && (
              <BoxContainer
                clickPaginationHandler={clickPaginationHandler}
                items={denied}
                section="denied"
              />
            )}
          </FetchApiContext.Provider>
        </pageContext.Provider>
      </div>
    </div>
  );
};

export default AdminEvent;
