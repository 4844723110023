/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';

const GetComponent = (name, props) => {
  const Component = require('./' + name + '').default;
  return <Component {...props} />;
};

export default GetComponent;
