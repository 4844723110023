import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { Button, Modal } from '../index';
import styles from './BoxRow.scss';
import { AuthContext } from '../../../contexts/AuthContext';
import { FetchApiContext } from '../../../contexts/FetchApiContext';
import { fetchUrl } from '../../../helpers/fetchUrl';
import * as USERS from '../../../constants/users';

function TourGuidesRowItems(props) {
  const { page, item, timesArray } = props;
  const auth = React.useContext(AuthContext);
  const fetchApi = React.useContext(FetchApiContext);

  const { booking, calendar } = item;
  const { token, userId } = auth;
  const [loading, setLoading] = useState(false);
  const itemStatus = booking.status;
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [modalData, setModalData] = useState({
    openModal: false,
    text: '',
    onConfirm: null,
  });
  const openToggle = open ? styles.open : '';

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const denyApprovedModal = () => {
    setModalData({
      openModal: true,
      text: 'Cancel approval',
      onConfirm: denyApprovedHandler,
    });
  };

  const denyApprovedHandler = () => {
    const requestBody = {
      query: `
      mutation{
        removeTourUser(removeTourUserInput: {
          userId: ${auth.userId},
          tour : "${booking.tourId}"
        })
      }
      `,
    };
    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });

    const requestBodyBooking = {
      query: `
      mutation {
        removeBookingsTourGuides(tourId: "${booking.tourId}", userId: "${
        auth.userId
      }") {
        id
        status
        tourId
        tourGuides
        tourGuidesAccepted
        tourGuidesConfirmed
      }
      }
      `,
    };
    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBodyBooking),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const finalizeModal = () => {
    setModalData({
      openModal: true,
      text: 'Accept Tour',
      booking,
      onConfirm: finalizeHandler,
    });
  };

  const finalizeHandler = () => {
    const requestBody = {
      query: `
      mutation {
        updateBookings(
          updateBookingInput: { tourId:${
            booking.tourId
          }, tourGuidesAccepted: ["${auth.userId}"] }
        ) {
          id
          status
          tourId
          tourGuides
          tourGuidesAccepted
        }
      }
      `,
    };
    fetch(fetchUrl('graphql'), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData) {
          fetchApi();
          setModalData({ openModal: false, text: '', onConfirm: null });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (calendar && calendar.times) {
      const startIndex = timesArray.findIndex(x => x.id === calendar.times[2]);
      const EndIndex = timesArray.findIndex(
        x => x.id === calendar.times[calendar.times.length - 3],
      );
      setStartTime(timesArray[startIndex].label);
      setEndTime(timesArray[EndIndex].label);
    }
  }, [item]);

  return (
    <div
      className={cx(
        styles.box__row,
        item.type === 'event' ? styles.event : '',
        styles.tourguide,
        styles[`box__row--${page}`],
        styles[
          `box__row--${
            booking.status === 'finalized' ? 'finalized' : 'pending'
          }`
        ],
        openToggle,
      )}
    >
      <div
        className={cx(
          styles.box__row__header,
          styles[
            `box__row__header--${
              booking.status === 'finalized' ? 'finalized' : 'pending'
            }`
          ],
          openToggle,
        )}
      >
        <a
          role="button"
          tabIndex={booking.tourId}
          onClick={() => {
            open ? setOpen(false) : setOpen(true);
          }}
        >
          <svg
            width="15"
            height="15"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.arrow}
          >
            <path d="M8.367 11.87l6.267-6.276c.244-.231.366-.52.366-.867 0-.346-.122-.635-.366-.866l-.722-.732a1.18 1.18 0 0 0-.866-.356c-.334 0-.626.119-.877.356L7.5 7.81l-4.68-4.68a1.18 1.18 0 0 0-.866-.356c-.333 0-.625.118-.876.356l-.722.732A1.18 1.18 0 0 0 0 4.727c0 .34.119.63.356.867l6.268 6.277c.25.237.542.356.876.356.34 0 .63-.119.867-.356z" />
          </svg>
        </a>
      </div>

      <div
        className={cx(
          styles.box__row__body,
          styles.box__row__body__3col,
          styles.box__row__body__light,
          styles[
            `box__row__body--${
              booking.status === 'finalized' ? 'finalized' : 'pending'
            }`
          ],
        )}
      >
        <div className={styles.box__row__body__item}>
          <div>Booking ID</div>
          <div>#{booking.tourId}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Requestor Name</div>
          <div>
            {item.requestorName} ({item.requestorCompany})
          </div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Date & Time</div>
          <div>
            {moment.monthsShort(calendar.month - 1)} {calendar.day},{' '}
            {item.type === 'event' ? `${startTime} to ${endTime}` : startTime}
          </div>
        </div>
      </div>
      <div
        className={cx(styles.box__row__body, styles[`box__row__body--pending`])}
      >
        <div className={styles.box__row__body__item}>
          <div>Requestor Title</div>
          <div>{item.requestorTitle}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Department/Team</div>
          <div>{item.requestorTeam}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Requestor Email</div>
          <div>{item.requestorEmail}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Requestor Phone</div>
          <div>{item.requestorPhone}</div>
        </div>

        {/* <div className={styles.box__row__body__item}>
          <div>Executive Sponsor Name</div>
          <div>{item.sponsorName}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Executive Sponsor Title</div>
          <div>{item.sponsorTitle}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Executive Sponsor Department/Team</div>
          <div>{item.sponsorTeam}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Executive Sponsor Email</div>
          <div>{item.sponsorEmail}</div>
        </div> */}
        <div className={styles.box__row__body__item}>
          <div>Tour Type</div>
          <div>{toTitleCase(item.tourType)}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Group Name</div>
          <div>{item.tourTypeName}</div>
        </div>

        <div className={styles.box__row__body__item}>
          <div>Number of Attendees</div>
          <div>{item.numAttendees}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Catering</div>
          <div>{toTitleCase(item.catering)}</div>
        </div>
        {item.type === 'event' && (
          <>
            <div className={styles.box__row__body__item}>
              <div>Are you the On-Site Contact?</div>
              <div>{item.tourContact}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Contact Name</div>
              <div>{item.tourContactName}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Contact Email</div>
              <div>{item.tourContactEmail}</div>
            </div>
            <div className={styles.box__row__body__item}>
              <div>Contact Phone</div>
              <div>{item.tourContactPhone}</div>
            </div>
          </>
        )}
      </div>
      <div
        className={cx(
          styles.box__row__body,
          styles.box__row__body__2col,
          styles.box__row__body__text,
          item.type === 'event' ? styles.box__row__body__light : '',
          styles[`box__row__body--pending`],
        )}
      >
        <div className={styles.box__row__body__item}>
          <div>Purpose of Event</div>
          <div>{item.tourPurpose}</div>
        </div>
        <div className={styles.box__row__body__item}>
          <div>Additional Notes</div>
          <div>{item.notes === '' ? ' ' : item.notes}</div>
        </div>
      </div>
      <div className={styles.box__row__footer}>
        <div className={styles.box__row__footer__item}>
          <div>Date Requested</div>
          <div>{item.createdAt.substring(0, 10).replace(/-/g, ' / ')}</div>
        </div>
        <div className={styles.box__row__footer__item} />
        {booking.status !== 'finalized' && (
          <div className={styles.box__row__footer__item}>
            <button
              className={cx(styles.btn, styles['btn--finalize'])}
              onClick={() => {
                finalizeModal();
              }}
            >
              Accept
            </button>

            <button
              onClick={() => {
                denyApprovedModal();
              }}
              className={cx(styles.btn, styles['btn--deny'])}
            >
              Decline
            </button>
          </div>
        )}
      </div>

      <Modal canConfirm setModalData={setModalData} modalData={modalData}>
        {modalData.text}
      </Modal>
    </div>
  );
}

export default TourGuidesRowItems;
