import React, { useState, useEffect } from 'react';

import { useForm, useStep } from 'react-hooks-helper';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { useGlobalState, percentCalc } from '../../../../contexts/globalState';
import { TimesArrayContext } from '../../../../contexts/TimesArray';

import styles from '../../Forms.scss';

import GetComponent from './Steps';

const ADD_TOUR = gql`
  mutation createTours($tour: TourInput!) {
    createTours(TourInput: $tour) {
      id
    }
  }
`;

const defaultData = {
  requestorName: '',
  requestorTitle: '',
  requestorTeam: '',
  requestorEmail: '',
  requestorPhone: '',
  requestorCompany: '',
  sponsorName: '',
  sponsorTitle: '',
  sponsorTeam: '',
  sponsorEmail: '',
  tourType: '',
  tourTypeName: '',
  tourPurpose: '',
  numAttendees: '',
  catering: '',
  notes: '',
  type: 'tour',
};
const defaultDataFilled = {
  requestorName: 'John Doe',
  requestorTitle: 'Title',
  requestorTeam: 'Team',
  requestorEmail: 'john@email.com',
  requestorPhone: '4160000000',
  requestorCompany: 'HP',
  sponsorName: 'John Smith',
  sponsorTitle: 'Sponsor Title',
  sponsorTeam: 'Sponsor Team',
  sponsorEmail: 'smith@email.com',
  tourType: 'internal',
  tourTypeName: 'Visiting Group Name',
  tourPurpose: 'Media Event',
  numAttendees: '12',
  catering: 'yes',
  notes: 'This is notes',
  type: 'tour',
};
const MultiStepTourForm = () => {
  const [addTour, { data }] = useMutation(ADD_TOUR);
  const timesArray = React.useContext(TimesArrayContext);
  const [currentStep, setCurrentStep] = useGlobalState('currentStep');
  const [paginationStep, setPaginationStep] = useGlobalState('steps');

  const [formData, setForm] = useForm(
    process.env.NODE_ENV === 'development' ? defaultDataFilled : defaultData,
  );
  const [dateTime, setDateTime] = useState();
  const [formDataAll, setFormDataAll] = useState();

  const submitFormHandler = () => {
    return addTour({
      variables: { tour: formDataAll },
    });
  };

  const stepsArr = [
    {
      id: 0,
      component: 'DateTime',
      props: { dateTime, setDateTime, timesArray },
    },
    {
      id: 1,
      component: 'ReqInfo',
      props: { dateTime, setDateTime, timesArray },
    },
    { id: 2, component: 'SponsorInfo' },
    { id: 3, component: 'TypeOfTour' },
    { id: 4, component: 'TourPurpose' },
    { id: 5, component: 'NumAttendees' },
    { id: 6, component: 'Catering' },
    { id: 7, component: 'Notes' },
    { id: 8, component: 'Terms' },
    { id: 9, component: 'ReviewForm', props: { submitFormHandler } },
    { id: 10, component: 'SubmissionForm' },
  ];

  const { index, step, navigation } = useStep({
    initialStep: 0,
    steps: stepsArr,
  });

  useEffect(() => {
    setPaginationStep(stepsArr.length - 2);
    setCurrentStep(index + 1);
    setFormDataAll({ ...formData, dateTime });
    percentCalc(stepsArr.length - 2, index + 1);
    // console.table('formDataAll', { ...formData, dateTime });
  }, [formData, dateTime, index]);

  const props = {
    formData,
    setForm,
    navigation,
    step,
    index,
  };
  const compIndex = stepsArr.findIndex(s => s.id === index);

  return (
    <div className={styles.form}>
      {GetComponent(stepsArr[compIndex].component, {
        ...props,
        ...stepsArr[compIndex].props,
      })}
    </div>
  );
};

export default MultiStepTourForm;
