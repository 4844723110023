import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const ReqInfo = ({ navigation, step, index }) => {
  const { next, previous } = navigation;
  const [value, setValue] = useState(false);

  return (
    <>
      <h4>
        <span>{index + 1}</span> Terms & Conditions
      </h4>
      <div className={styles.terms}>
        <div className={styles.terms__wrapper}>
          <div className={styles.terms__content}>
            <h3>Background</h3>
            <p>
              The property (house and garage) at 367 Addison Avenue is the
              central part of the Hewlett-Packard Company Archives, LLC.
              Employees of Hewlett Packard Enterprise and HP Inc. may request a
              visit or event by completing this request form.
            </p>
            <h3>Instructions</h3>
            <p>
              Once your request is approved, you will receive a calendar invite
              and e-mail confirmation for your records. To allow for time to
              confirm a tour guide and catering, the standard response time to
              completely schedule a request is approximately 72 business hours.
            </p>
            <h3>Hours of Operation</h3>
            <p>
              Daytime Hours: 9:00am - 5:00pm Monday, Tuesday, Thursday, Friday
              <br />
              Evening Hours: 5:30pm - 9:00pm Monday, Tuesday, Thursday
              <br />
              <br /> + The site is closed on Wednesdays for maintenance and
              cleaning.
              <br /> + The site is closed on Friday evenings.
              <br /> + If the requested date is already booked, you may reach
              out to the other party to request a change. There is no guarantee
              of a change.
              <br /> + Other closing dates may occur throughout the year for
              large maintenance projects, etc. These dates will be shared as
              they occur.
              <br /> + The upstairs apartment is CLOSED to the public. There is
              no access to this private property.
            </p>
            <h3> Group Sizes</h3>
            <p>
              Due to space constraints, the maximum number of attendees for
              events are as follows:
              <br />
              <br />
              Tours and Simple Visits - 45 minutes - Less than 15 people
              <br />
              Evening & Lunch Receptions (Drinks, Appetizers) - Typically 2
              hours - Less than 25 people
              <br />
              Seated Meal (Plated) - Typically 2 hours - Less than 10 people
            </p>
            <h3>Additional Terms and Conditions</h3>
            <ul>
              <li>
                Requests can be made by employees of Hewlett Packard Enterprise
                or HP Inc.
              </li>
              <li>Requests are managed on a first-come, first-served basis.</li>
              <li>
                Customer visits/events take priority and should be focused on
                top accounts.
              </li>
              <li>Requests should be made at least two weeks in advance.</li>
              <li>
                Staff events should be limited to events sponsored by a Manager
                at Level 3, or above. (CEO of each company being Level 0).
              </li>
              <li>
                All associated costs are billed back to the requesting
                organization. <br />
                (Examples are – but not limited to – additional security,
                non-standard hours’ security, cleaning, food and beverage
                services, damage)
                <ul>
                  <li>
                    Funds may be remitted to the bank account of the
                    Hewlett-Packard Company Archives, LLC (details provided upon
                    request) or by credit card.
                  </li>
                  <li>
                    Catering costs and transportation fees are paid to the
                    vendors directly by the requesting organization.
                  </li>
                </ul>
              </li>
              <li>
                The Addison Avenue Property is a non-smoking location.
                <ul>
                  <li>
                    The no smoking policy applies to the property, the sidewalk
                    and street in front of the house and the sidewalk and street
                    in front of the neighbors.
                  </li>
                </ul>
              </li>
              <li>
                Transportation is the responsibility of the requester. Addison
                Avenue is a residential street and parking is at a premium.
                <ul>
                  <li>
                    Car sharing (or services such as Lyft) is required for
                    groups of less than 6 people (2 cars or less).
                  </li>
                  <li>
                    For groups of 6 or more, contact ABC Transportation
                    (www.abctrans.com) to schedule transport from the HPE or HPI
                    site to Addison Avenue and back.
                  </li>
                </ul>
              </li>
              <li>
                Catering requests must be made at least two weeks in advance of
                the event. The requesting company/team from HPE or HPI is
                responsible for securing catering services from the Executive
                Catering Team for HPE and HPI
                (jonathan.chartrand@compass-usa.com).
              </li>
              <li>
                Once catering needs are determined, the requester is responsible
                for finalizing the catering costs, menus, and plans.
              </li>
              <li>
                All damages are the financial responsibility of the requester.
              </li>
              <li>
                Catering services require a $1,200 minimum for food and $600 for
                labor, plus a 20% service charge on all food and labor. Please
                confirm all catering fees directly with Charmaine.
              </li>
            </ul>
            <h3>Escalation</h3>
            <p>
              If you have an urgent question or change to your request, please
              note “URGENT: ESCALATION” in the e-mail subject line. When
              scheduling an escalation, please allow up to 24 business hours for
              a response from the Addison Avenue Scheduling Team.
            </p>
            <h3>Responsibilities for the Day of the Visit</h3>
            <ul>
              <li>
                The Addison Avenue Scheduling Team will communicate instructions
                regarding site access/security to the requester, once the event
                is scheduled and added to the Addison Avenue Calendar.
              </li>
              <li>
                Visits/Events must be accompanied by a named representative of
                Hewlett Packard Enterprise or HP Inc., depending on the company
                of the requester.
                <ul>
                  <li>
                    This representative will be a trained tour guide, assigned
                    by the Addison Avenue Scheduling Team.
                  </li>
                  <li>
                    Tour Guide information and instructions are provided to
                    appropriate parties under separate cover.
                  </li>
                </ul>
              </li>
              <li>
                Once the event concludes, the responsible party will complete a
                final walk-through, and notify security of departure at
                916.785.4919.
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className={cx(stylesForm.checkbox__small, stylesForm.full)}>
        <input
          type="checkbox"
          id="terms"
          name="terms"
          checked={value}
          onChange={() => setValue(!value)}
        />
        <label htmlFor="terms">I agree to the terms outlined above.</label>
      </div>

      <Navigation navigation={navigation} isValid={value} />
    </>
  );
};

export default ReqInfo;
