import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function validateEmail(email) {
  const validate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate.test(email);
}

const SponsorInfo = ({ setForm, formData, navigation, step, index }) => {
  const { sponsorName, sponsorTitle, sponsorTeam, sponsorEmail } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState({ err: '' });
  const emailFeild = createRef();

  const prevEmail = usePrevious(sponsorEmail);
  KeyboardNav(step, isValid, navigation);

  function emailOnFocus() {
    if (sponsorEmail !== '' && !validateEmail(sponsorEmail)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else {
      setEmailError({ err: '' });
    }
  }

  useEffect(() => {
    if (
      sponsorName !== '' &&
      sponsorTitle !== '' &&
      sponsorTeam !== '' &&
      sponsorEmail !== '' &&
      validateEmail(sponsorEmail)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (
      prevEmail &&
      prevEmail.length > sponsorEmail.length &&
      !validateEmail(sponsorEmail)
    ) {
      setEmailError({ err: 'Email is not Valid!' });
    } else if (validateEmail(sponsorEmail)) {
      setEmailError({ err: '' });
    }

    emailFeild.current.addEventListener('focusout', emailOnFocus);

    return () => {
      emailFeild.current
        ? emailFeild.current.removeEventListener('focusout', emailOnFocus)
        : null;
    };
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>{index + 1}</span> Executive Sponsor Information
      </h4>
      <Input
        name="sponsorName"
        placeholder="Name *"
        value={sponsorName}
        onChange={setForm}
      />
      <Input
        name="sponsorTitle"
        placeholder="Title *"
        value={sponsorTitle}
        onChange={setForm}
        half
      />
      <Input
        name="sponsorTeam"
        placeholder="Department/Team *"
        value={sponsorTeam}
        onChange={setForm}
        half
      />

      <div className={cx(stylesForm.input__wrapper)}>
        <label
          htmlFor="sponsorEmail"
          className={cx(
            stylesForm.input,
            emailError.err !== '' ? stylesForm.input__email_error : '',
          )}
        >
          <input
            type="text"
            placeholder=" "
            id="sponsorEmail"
            name="sponsorEmail"
            value={sponsorEmail}
            onChange={setForm}
            ref={emailFeild}
            autoComplete="nope"
          />
          <span className={stylesForm.label}>Email *</span>
          <span className={stylesForm.border} />
          {emailError.err !== '' && (
            <span className={stylesForm.email__error}>{emailError.err}</span>
          )}
        </label>
      </div>

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default SponsorInfo;
