import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../../Header/Header.scss';

const NavLinkBase = props => {
  const { children, to, location } = props;
  const isActive = location.pathname === to;
  const active = isActive ? styles.active : '';

  return (
    <Link to={to} className={active} onClick={() => props.navOpen(false)}>
      {children}
    </Link>
  );
};

export default NavLinkBase;
