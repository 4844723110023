import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';

import styles from './Form.scss';

const Radio = ({ label, id, checked, err = '', ...otherProps }) => {
  const [selectedClass, setSelectedClass] = useState(false);

  useEffect(() => {
    checked ? setSelectedClass(true) : setSelectedClass(false);
  }, [checked]);

  return (
    <div
      className={cx(
        styles.radio,
        selectedClass ? styles['radio--selected'] : '',
        err.err !== '' && id === 'notApproved' ? styles['radio--err'] : '',
      )}
    >
      <label htmlFor={id} className={styles.radio__label}>
        {label}
      </label>
      <input
        id={id}
        className={styles.radio__input}
        checked={checked}
        {...otherProps}
      />
    </div>
  );
};

export default Radio;
