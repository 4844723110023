/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

export const tourContactList = [
  {
    id: 'yes',
    label: 'Yes',
  },
  {
    id: 'no',
    label: 'No',
  },
];
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function validateEmail(email) {
  const validate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate.test(email);
}
const TourContact = ({ setForm, formData, navigation, step, index }) => {
  const {
    tourContact,
    tourContactName,
    tourContactEmail,
    tourContactPhone,
  } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState({ err: '' });
  const emailFeild = createRef();
  const prevEmail = usePrevious(tourContactEmail);

  KeyboardNav(step, isValid, navigation);

  function emailOnFocus() {
    if (tourContactEmail !== '' && !validateEmail(tourContactEmail)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else {
      setEmailError({ err: '' });
    }
  }

  useEffect(() => {
    if (tourContact !== '' && tourContact === 'yes') {
      setIsValid(true);
    } else if (tourContactName !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    if (
      prevEmail &&
      prevEmail.length > tourContactEmail.length &&
      !validateEmail(tourContactEmail)
    ) {
      setEmailError({ err: 'Email is not Valid!' });
    } else if (validateEmail(tourContactEmail)) {
      setEmailError({ err: '' });
    }

    emailFeild.current
      ? emailFeild.current.addEventListener('focusout', emailOnFocus)
      : null;
    return () => {
      emailFeild.current
        ? emailFeild.current.removeEventListener('focusout', emailOnFocus)
        : null;
    };
  }, [formData, isValid]);

  return (
    <div>
      <h4>
        <span>{index + 1}</span> Are you the On-Site Contact? *
      </h4>

      <div className={cx(styles.form__multiple, styles.form__multiple__col1)}>
        {tourContactList.map(type => {
          const { id, label } = type;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="tourContact"
              value={id}
              checked={tourContact === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
      </div>

      {tourContact === 'no' && (
        <>
          <Input
            name="tourContactName"
            placeholder="What is the Name of the On-Site Contact? *"
            value={tourContactName}
            onChange={setForm}
            label
          />
          <div
            className={cx(
              stylesForm.input__wrapper,
              stylesForm.half,
              stylesForm.half__odd,
            )}
          >
            <label
              htmlFor="tourContactEmail"
              className={cx(
                stylesForm.input,
                emailError.err !== '' ? stylesForm.input__email_error : '',
              )}
            >
              <input
                type="text"
                placeholder=" "
                id="tourContactEmail"
                name="tourContactEmail"
                value={tourContactEmail}
                onChange={setForm}
                ref={emailFeild}
                autoComplete="nope"
              />
              <span className={stylesForm.label}>Tour Contact email</span>
              <span className={stylesForm.border} />
              {emailError.err !== '' && (
                <span className={stylesForm.email__error}>
                  {emailError.err}
                </span>
              )}
            </label>
          </div>

          <Input
            name="tourContactPhone"
            placeholder="Tour Contact phone"
            className={stylesForm.half__even}
            value={tourContactPhone}
            onChange={setForm}
            half
          />
        </>
      )}
      <Navigation navigation={navigation} isValid={isValid} />
    </div>
  );
};

export default TourContact;
