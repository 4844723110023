import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Input, Radio } from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

import completeIcon from '../../../../../images/complete-icon.svg';
import backArrow from '../../../../../images/arrow.svg';
import * as ROUTES from '../../../../../constants/routes';

const SubmissionForm = ({ navigation, step }) => {
  const { next, previous, go } = navigation;

  return (
    <>
      <div className={styles.form__submission}>
        <img
          className={styles.form__submission__icon}
          src={completeIcon}
          alt=""
        />
        <h2>Your Event Has Been Submitted!</h2>
        <h3>
          You will be contacted when your tour has been approved or denied.
        </h3>
        <h4>Back to Home</h4>
        <Link to={ROUTES.HOME}>
          <button>
            <img src={backArrow} alt="" />
          </button>
        </Link>
      </div>
    </>
  );
};

export default SubmissionForm;
