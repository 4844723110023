/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import * as ROUTES from '../../constants/routes';

import logo from '../../images/logo.png';
import { Button } from '../../components/shared';
import Modal from '../../components/shared/Modal/index';

import styles from './Home.scss';
import CalendarPreviw from '../../components/shared/Modal/CalendarPreview';

const Home = props => {
  const [modalData, setModalData] = useState({
    openModal: false,
  });
  const [pinCodeStatus, setPinCodeStatus] = useState();
  const config = {};

  const byPassLogin = () => {
    props.history.push(ROUTES.FORM);
  };
  const handleStartRequest = () => {
    window.location = 'https://hewlettpackardgarage.com/signin/login';
    // if (pinCodeStatus) {
    //   props.history.push(ROUTES.FORM);
    // } else {
    //   props.history.push(ROUTES.FORM);
    //   // setModalData({
    //   //   openModal: true,
    //   //   text: 'Pin Code Required',
    //   //   body: 'pinCode',
    //   // });
    // }
    // };
  };

  useEffect(() => {
    // const _pinCode = JSON.parse(localStorage.getItem('pinCode'));
    // if (_pinCode) {
    //   setPinCodeStatus(_pinCode.pin);
    // }
    // console.log('pinCodeStatus', pinCodeStatus);
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.home__header}>
        <img src={logo} alt="" />
        <Button
          btnType="green"
          className={styles.btn}
          href="mailto:addisonave@heritagewerks.com"
          external
        >
          Contact
        </Button>
      </div>
      <div className={styles.home__tiles}>
        <div className={styles.home__tiles__start}>
          <div className={styles.home__tiles__start__content}>
            <h2>A home for innovation</h2>
            <p>The HP Garage—the Birthplace of Silicon Valley</p>
          </div>
          <div className={styles.home__tiles__start__btn}>
            <div className={styles.home__tiles__start__content}>
              <Button
                href="https://hewlettpackardgarage.com/signin/login"
                external
                redirect
                btnType="green"
              >
                HPE Login
              </Button>
            </div>
            <a
              role="button"
              tabIndex="0"
              onClick={() => {
                setModalData({
                  openModal: true,
                  text: 'Calendar Preview',
                  body: 'preview',
                });
              }}
            >
              See Availability
            </a>
            {/* <a role="button" tabIndex="0" onClick={byPassLogin}>
              Bypass Login
            </a> */}
          </div>
        </div>
      </div>
      <Modal setModalData={setModalData} modalData={modalData}>
        {modalData.text}
      </Modal>
    </div>
  );
};

export default Home;
