import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import styles from './Calendar.scss';

const Calendar = props => {
  const { year, month, selectedDay, setSelectedDay, preview } = props;

  const date = `${year}-${month}`;
  const blankDaysBeginLen = moment(date, 'YYYY-MM')
    .startOf('month')
    .format('d');
  const daysInMonthArray = moment(date, 'YYYY-MM').daysInMonth();

  const blankDaysBegin = [];
  const daysInMonth = [];
  const weekendsInMonth = [];
  const dayClickHandler = day => {
    setSelectedDay(day);
  };

  for (let i = 0; i < blankDaysBeginLen; i += 1) {
    blankDaysBegin.push(<td key={i * 80} />);
  }

  for (let day = 1; day <= daysInMonthArray; day += 1) {
    const iscurrentDate = moment(new Date(`${date}-${day}`)).isSame(
      moment(),
      'day',
    );
    const isBeforeToday = moment(new Date(`${date}-${day}`)).isBefore(
      moment(),
      'day',
    );

    daysInMonth.push(
      <td
        key={day}
        className={cx(
          styles.calendar__tabel__body__day,
          preview ? styles.preview : '',
          iscurrentDate ? styles.today : '',
          selectedDay === day ? styles.current : '',
        )}
      >
        {isBeforeToday || iscurrentDate ? (
          <span>
            <span className={styles.calendar__tabel__body__day__num}>
              {day}
            </span>
          </span>
        ) : (
          <a role="button" tabIndex={day} onClick={() => dayClickHandler(day)}>
            <span className={styles.calendar__tabel__body__day__num}>
              {day}
            </span>
          </a>
        )}
      </td>,
    );
  }

  const totalSlots = [...blankDaysBegin, ...daysInMonth];
  const rows = [];
  let cells = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row);
    } else {
      const insertRow = cells.slice();
      rows.push(insertRow);
      cells = [];
      cells.push(row);
    }
    if (i === totalSlots.length - 1) {
      const insertRow = cells.slice();
      rows.push(insertRow);
    }
  });

  const trElems = rows.map((d, i) => {
    return (
      <tr
        key={(i * 100).toString()}
        className={styles.calendar__tabel__body__days}
      >
        {d}
      </tr>
    );
  });

  return (
    <div className={styles.calendar}>
      <table className={styles.calendar__tabel}>
        <thead>
          <tr className={styles.calendar__tabel__header}>
            <td colSpan="7">
              <span>{moment(date, 'YYYY-MM').format('MMMM')}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.calendar__tabel__body__header}>
            {moment.weekdaysShort().map(day => {
              return <td key={day}>{day}</td>;
            })}
          </tr>
          {trElems}
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;
