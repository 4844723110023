import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  steps: 0,
  currentStep: 0,
  percent: 0,
};

const {
  GlobalStateProvider,
  setGlobalState,
  useGlobalState,
} = createGlobalState(initialState);

export const percentCalc = (steps, current) => {
  const percent = (100 * current) / steps;
  setGlobalState('percent', () => percent);
};

export { GlobalStateProvider, useGlobalState };
