import { Parser } from 'json2csv';

const today = new Date();
const date =
  new Date().getFullYear() +
  '-' +
  (new Date().getMonth() + 1) +
  '-' +
  new Date().getDate();
const filename = `tours-requests-${date}`;
const fields = [
  'id',
  'requestorName',
  'requestorTitle',
  'requestorTeam',
  'requestorEmail',
  'requestorPhone',
  'requestorCompany',
  'sponsorName',
  'sponsorTitle',
  'sponsorTeam',
  'sponsorEmail',
  'tourType',
  'tourTypeName',
  'tourPurpose',
  'numAttendees',
  'catering',
  'notes',
  'createdAt',
  'booking.tourId',
  'booking.status',
];

export const exportCsv = async (toursList, setExportUrl) => {
  const opts = { fields };
  try {
    const parser = await new Parser(opts);
    const csv = await parser.parse(toursList);
    const blob = await new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = await URL.createObjectURL(blob);
    setExportUrl(url);
  } catch (err) {
    console.error(err);
  }
};
