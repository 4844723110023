/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Loader from 'react-loader-spinner';

import { AuthContext } from '../../../../../contexts/AuthContext';
import { Input, Radio, Modal } from '../../../../../components/shared';
import { dateToString } from '../../../../../helpers/date';
import * as ROUTES from '../../../../../constants/routes';
import { fetchUrl } from '../../../../../helpers/fetchUrl';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';
import { tourTypeList } from './TypeOfTour';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const ReviewForm = ({
  formData,
  navigation,
  step,
  auth,
  history,
  submitFormHandler,
}) => {
  const {
    requestorName,
    requestorTitle,
    requestorTeam,
    requestorEmail,
    requestorPhone,
    requestorCompany,
    sponsorName,
    sponsorTitle,
    sponsorTeam,
    sponsorEmail,
    tourType,
    tourTypeName,
    tourPurpose,
    numAttendees,
    catering,
    tourContact,
    tourContactName,
    tourContactEmail,
    tourContactPhone,
    tourNeeded,
    notes,
  } = formData;

  const { next, previous, go } = navigation;
  const { token } = auth;
  const [modalData, setModalData] = useState({
    openModal: false,
    text: '',
    onConfirm: null,
  });
  const [loading, setLoading] = useState(false);

  const submitModal = () => {
    setModalData({
      openModal: true,
      text: 'Submit Form',
      onConfirm: handleSubmit,
    });
  };

  const handleSubmit = () => {
    setModalData({
      openModal: false,
    });
    setLoading(true);
    submitFormHandler().then(
      res => {
        setTimeout(() => {
          setLoading(false);
          next();
        }, 2000);
      },
      error => {
        console.log('error', error);
        setLoading(false);
        setModalData({
          openModal: true,
          text: 'Sorry! There was an error, please try again later.',
          error: true,
        });
      },
    );
  };

  useEffect(() => {}, [loading]);
  return (
    <>
      {loading && (
        <>
          <div className={styles.form__loader}>
            <Loader type="Grid" color="#0ca0dd" height={50} width={50} />
          </div>
        </>
      )}
      {!loading && (
        <>
          <div className={styles.form__review}>
            <h2>Review</h2>
            {requestorName !== '' && (
              <div className={styles.form__review__item}>
                <h4>Name:</h4>
                <h2>{requestorName}</h2>
                <button onClick={() => go(1)}>Edit</button>
              </div>
            )}
            {requestorTitle !== '' && (
              <div className={styles.form__review__item}>
                <h4>Title:</h4>
                <h2>{requestorTitle}</h2>
                <button onClick={() => go(1)}>Edit</button>
              </div>
            )}

            {requestorTeam !== '' && (
              <div className={styles.form__review__item}>
                <h4>Department/Team:</h4>
                <h2>{requestorTeam}</h2>
                <button onClick={() => go(1)}>Edit</button>
              </div>
            )}

            {requestorEmail !== '' && (
              <div className={styles.form__review__item}>
                <h4>Email:</h4>
                <h2>{requestorEmail}</h2>
                <button onClick={() => go(1)}>Edit</button>
              </div>
            )}

            {requestorCompany !== '' && (
              <div className={styles.form__review__item}>
                <h4>Comapny:</h4>
                <h2>{requestorCompany}</h2>
                <button onClick={() => go(1)}>Edit</button>
              </div>
            )}

            {sponsorName !== '' && (
              <div className={styles.form__review__item}>
                <h4>Sponsor Name:</h4>
                <h2>{sponsorName}</h2>
                <button onClick={() => go(2)}>Edit</button>
              </div>
            )}
            {sponsorTitle !== '' && (
              <div className={styles.form__review__item}>
                <h4>Sponsor Title:</h4>
                <h2>{sponsorTitle}</h2>
                <button onClick={() => go(2)}>Edit</button>
              </div>
            )}

            {sponsorTeam !== '' && (
              <div className={styles.form__review__item}>
                <h4>Sponsor Department/Team:</h4>
                <h2>{sponsorTeam}</h2>
                <button onClick={() => go(2)}>Edit</button>
              </div>
            )}

            {sponsorEmail !== '' && (
              <div className={styles.form__review__item}>
                <h4>Sponsor Email:</h4>
                <h2>{sponsorEmail}</h2>
                <button onClick={() => go(2)}>Edit</button>
              </div>
            )}

            {tourType !== '' && (
              <div className={styles.form__review__item}>
                <h4>Type of tour:</h4>
                {tourTypeList.map(req => {
                  return req.id === tourType ? (
                    <h2 key={req.id}>{req.label}</h2>
                  ) : null;
                })}
                <button onClick={() => go(3)}>Edit</button>
              </div>
            )}

            {tourTypeName !== '' && (
              <div className={styles.form__review__item}>
                <h4>
                  {tourType === 'internal'
                    ? 'What is the name of the visiting group?'
                    : tourType === 'customer'
                    ? 'Who is the Customer?'
                    : tourType === 'outreach'
                    ? 'How would you categorize the outreach?'
                    : null}
                </h4>
                <h2>{tourTypeName}</h2>
                <button onClick={() => go(3)}>Edit</button>
              </div>
            )}
            {tourPurpose !== '' && (
              <div className={styles.form__review__item}>
                <h4>Tour purpose:</h4>
                <h2>{tourPurpose}</h2>
                <button onClick={() => go(4)}>Edit</button>
              </div>
            )}
            {numAttendees !== '' && (
              <div className={styles.form__review__item}>
                <h4>Number of Attendees:</h4>
                <h2>{numAttendees}</h2>
                <button onClick={() => go(5)}>Edit</button>
              </div>
            )}
            {catering !== '' && (
              <div className={styles.form__review__item}>
                <h4>Catering:</h4>
                <h2>{catering}</h2>
                <button onClick={() => go(6)}>Edit</button>
              </div>
            )}

            {tourContact !== '' && (
              <div className={styles.form__review__item}>
                <h4>Tour Contact:</h4>
                <h2>{tourContact}</h2>
                <button onClick={() => go(7)}>Edit</button>
              </div>
            )}
            {tourContactName !== '' && (
              <div className={styles.form__review__item}>
                <h4>Tour Contact Name:</h4>
                <h2>{tourContactName}</h2>
                <button onClick={() => go(7)}>Edit</button>
              </div>
            )}

            {tourContactEmail !== '' && (
              <div className={styles.form__review__item}>
                <h4>STour Contact Email:</h4>
                <h2>{tourContactEmail}</h2>
                <button onClick={() => go(7)}>Edit</button>
              </div>
            )}

            {tourContactPhone !== '' && (
              <div className={styles.form__review__item}>
                <h4>Tour Contact Phone:</h4>
                <h2>{tourContactPhone}</h2>
                <button onClick={() => go(7)}>Edit</button>
              </div>
            )}
            {tourNeeded !== '' && (
              <div className={styles.form__review__item}>
                <h4>Tour Needed:</h4>
                <h2>{tourNeeded}</h2>
                <button onClick={() => go(8)}>Edit</button>
              </div>
            )}
            {notes !== '' && (
              <div className={styles.form__review__item}>
                <h4>Notes:</h4>
                <h2>{notes}</h2>
                <button onClick={() => go(10)}>Edit</button>
              </div>
            )}
          </div>
          <div className={styles.form__navigation}>
            <button onClick={previous}>Previous</button>
            <button
              onClick={() => {
                submitModal();
              }}
            >
              Submit
            </button>
          </div>
        </>
      )}

      <Modal canConfirm setModalData={setModalData} modalData={modalData}>
        {modalData.text}
      </Modal>
    </>
  );
};

export default React.forwardRef((props, ref) => (
  <AuthContext.Consumer>
    {auth => <ReviewForm {...props} auth={auth} ref={ref} />}
  </AuthContext.Consumer>
));
