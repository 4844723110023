export const HOME = '/';
export const ADMIN = '/admin';
export const FORM = '/form';
export const ADMIN_TOUR = '/admin-tour';
export const ADMIN_TOUR_SPECIAL = '/admin-tour-special';
export const ADMIN_CALENDAR = '/admin-calendar';
export const ADMIN_PAGE = '/admin/:page';
export const ADMIN_TOURGUIDE_AVAILABLE = '/admin-tourguide-available';
export const ADMIN_TOURGUIDE_UPCOMING = '/admin-tourguide-upcoming';
export const LOGIN = '/login';
