import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Input, Checkbox, Navigation, KeyboardNav } from '..';

import styles from './Modal.scss';
import stylesForm from '../Form/Form.scss';

const GET_USERS = gql`
  query {
    allTourGuides {
      id
      firstName
      lastName
      role
      email
    }
  }
`;

const ApproveTourGuide = ({ modalData, setOpenModal, setModalData }) => {
  const [isValid, setIsValid] = useState(false);
  const [tourGuides, setTourGuides] = useState();
  const [tourGuideSelected, setTourGuideSelected] = useState();
  const { loading: getUsersLoading, error, data } = useQuery(GET_USERS);

  const tourGuideSlectHandler = e => {
    const index = tourGuides.findIndex(x => x.id === e.target.id);
    setTourGuideSelected(e.target.id);
  };

  useMemo(() => {
    if (data) {
      const _tourGuides = new Promise((resolve, reject) => {
        resolve(
          data.allTourGuides.filter(tourGuide =>
            modalData.booking.tourGuidesAccepted.includes(tourGuide.id),
          ),
        );
      }).then(res => {
        const _tourGuides_ = [];
        res.forEach(item => {
          _tourGuides_.push({ ...item, selected: false });
        });
        return _tourGuides_;
      });

      _tourGuides.then(res => setTourGuides(res));
    }
  }, [data]);

  useEffect(() => {
    setIsValid(tourGuideSelected);
  }, [tourGuides, tourGuideSelected, getUsersLoading, isValid]);

  if (getUsersLoading) return <p>Loading ...</p>;

  return (
    <>
      <h5>Select Final Tour Guide</h5>
      <div className={styles.modal__content__body__checkbox}>
        {tourGuides &&
          tourGuides.map(tourGuide => {
            const { id, firstName, lastName } = tourGuide;
            return (
              <div key={id} className={stylesForm.checkbox__small}>
                <input
                  type="radio"
                  id={id}
                  name="accepted"
                  checked={tourGuideSelected === id}
                  onChange={tourGuideSlectHandler}
                />
                <label htmlFor={id}>
                  {lastName ? `${firstName} ${lastName}` : firstName}
                </label>
              </div>
            );
          })}
      </div>
      <div className={styles.modal__content__actions}>
        <button
          className={cx(styles.btn, styles.btn__close)}
          onClick={() => {
            setOpenModal(false);
            setModalData({ openModa: false });
          }}
        >
          Close
        </button>
        <button
          className={cx(styles.btn)}
          onClick={() => {
            modalData.onConfirm(tourGuideSelected);
          }}
          disabled={!isValid}
        >
          Select & Approve
        </button>
      </div>
    </>
  );
};

export default ApproveTourGuide;
