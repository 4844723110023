/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Nav from './Nav';
import * as ROUTES from '../../constants/routes';
import * as USERS from '../../constants/users';

import { AuthContext } from '../../contexts/AuthContext';

import styles from './Header.scss';

import logo from '../../images/logo.png';

const Header = props => {
  const [open, setOpen] = useState(false);
  const openToggle = open ? styles.open : '';
  const { location } = props;
  const auth = React.useContext(AuthContext);
  return (
    <header className={styles.header}>
      <div className={styles.header__top}>
        <div className={cx(styles.header__top__content, openToggle)}>
          <div className={styles.logo}>
            <Link to="#">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div />
          <div className={styles.profile}>
            <div className={styles.profile__top}>
              <button onClick={() => auth.logout()}>Log Out</button>
            </div>
            <div className={styles.profile__bottom}>
              Welcome {auth.userName}
            </div>
          </div>
          <label
            onClick={() => {
              open ? setOpen(false) : setOpen(true);
            }}
            className={styles['header__top__content--toggle-label']}
          >
            <span />
          </label>
        </div>
      </div>
      <div className={styles.header__bottom}>
        <div className={styles.header__bottom__content}>
          <Nav navOpen={setOpen} auth={auth} location={location} />
        </div>
      </div>
    </header>
  );
};

export default Header;
