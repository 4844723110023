import React, { useState, useEffect, createRef, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import cx from 'classnames';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import * as ROUTES from '../../../constants/routes';

import { Button, Input } from '..';

import styles from './Modal.scss';
import stylesForm from '../Form/Form.scss';

const SEND_PIN_CODE = gql`
  query PinCodeEmail($email: String!) {
    pinCodeEmail(email: $email)
  }
`;
const CHECK_PIN_CODE = gql`
  query PinCode($pin: String!) {
    pinCode(pin: $pin)
  }
`;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function validateEmail(email) {
  const validate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate.test(email);
}
function validateEmailDomain(email) {
  let validate = false;
  if (email) {
    const idx = email.lastIndexOf('@');

    validate =
      idx > -1 &&
      (email.slice(idx + 1) === 'hp.com' ||
        email.slice(idx + 1) === 'hpe.com' ||
        email.slice(idx + 1) === 'heritagewerks.com' ||
        email.slice(idx + 1) === 'channel1media.com');
  }
  return validate;
}

const PinCode = props => {
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState({ err: '' });
  const [pinError, setPinError] = useState({ err: '' });
  const [loginError, setLoginError] = useState();
  const [pinCode, setPinCode] = useState();
  const [email, setEmail] = useState();
  const [pending, setPending] = useState();

  const [sendPinCode] = useLazyQuery(SEND_PIN_CODE, {
    onCompleted: res => {
      console.log('resemail', res);
    },
  });

  const [checkPinCode] = useLazyQuery(CHECK_PIN_CODE, {
    onCompleted: res => {
      setLoginError(null);
      if (res.pinCode) {
        setPinError({ err: '' });
        const now = new Date();
        localStorage.setItem(
          'pinCode',
          JSON.stringify({
            pin: true,
          }),
        );
        props.history.push(ROUTES.FORM);
      } else {
        setPinError({ err: 'PinCode is incorrect!' });
      }
    },
  });

  const emailFeild = createRef();
  const pinCodeFeild = createRef();

  const prevEmail = usePrevious(email);
  const prevPass = usePrevious(pinCode);

  function emailOnFocus() {
    if (email !== '' && !validateEmail(email)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else if (!validateEmailDomain(email)) {
      setEmailError({ err: 'You must use your corporate email address!' });
    } else {
      setEmailError({ err: '' });
    }
  }

  async function resJson(res) {
    const respond = await res.json();
    return respond;
  }

  const handleSubmit = (em, pincode) => {
    if (isValid) {
      if (!pending) {
        sendPinCode({
          variables: {
            email: em,
          },
        });
        setPending(true);
      }

      if (pincode && pincode !== '') {
        checkPinCode({
          variables: {
            pin: pincode,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (
      email !== '' &&
      pinCode !== '' &&
      validateEmail(email) &&
      validateEmailDomain(email)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (prevEmail && prevEmail.length > email.length && !validateEmail(email)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else if (validateEmail(email)) {
      setEmailError({ err: '' });
    }

    if (loginError === 'User does not exist!') {
      setEmailError({ err: 'User does not exist!' });
    } else {
      setEmailError({ err: '' });
    }

    emailFeild.current &&
      emailFeild.current.addEventListener('focusout', emailOnFocus);

    return () => {
      emailFeild.current
        ? emailFeild.current.removeEventListener('focusout', emailOnFocus)
        : null;
    };
  }, [isValid, email, pinCode, loginError]);

  return (
    <div className={styles.pincode}>
      {!pending && (
        <>
          <h4>Enter the email you wish to receive your pin code</h4>

          <label
            htmlFor="email"
            className={cx(
              stylesForm.input,
              emailError.err !== '' ? stylesForm.input__email_error : '',
            )}
          >
            <input
              type="text"
              placeholder=" "
              id="email"
              onChange={e => setEmail(e.target.value)}
              ref={emailFeild}
              autoComplete="off"
            />
            <span className={stylesForm.label}>Type your email here …</span>
            <span className={stylesForm.border} />
            {emailError.err !== '' && (
              <span className={stylesForm.email__error}>{emailError.err}</span>
            )}
          </label>
        </>
      )}
      {pending && (
        <>
          <h4>Please check your email for the pin code</h4>
          <label
            htmlFor="pinCode"
            className={cx(
              stylesForm.input,
              pinError.err !== '' ? stylesForm.input__email_error : '',
            )}
          >
            <input
              type="pinCode"
              placeholder=" "
              id="pinCode"
              name="pinCode"
              onChange={e => setPinCode(e.target.value)}
              ref={pinCodeFeild}
              autoComplete="off"
            />
            <span className={stylesForm.label}>Type your pinCode here …</span>
            <span className={stylesForm.border} />
            {pinError.err !== '' && (
              <span className={stylesForm.email__error}>{pinError.err}</span>
            )}
          </label>
        </>
      )}
      <div className={styles.btn}>
        <button onClick={() => handleSubmit(email, pinCode)}>
          {pending ? 'Continue' : 'Get Pin Code'}
        </button>
      </div>
    </div>
  );
};

export default withRouter(PinCode);
