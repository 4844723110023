import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';

import App from './App.js';

const headers = {};

// const httpLink = createHttpLink({
//   uri: `https://api.hp.vipfanportal.com/graphql`,
// });
const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? `http://localhost:4000/graphql`
      : `https://api.hp.vipfanportal.com/graphql`,
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      if (message === 'jwt expired') {
        localStorage.removeItem('jwtAuth');
        // eslint-disable-next-line no-alert
        // alert('Your session expired! Please login again.');
        // location.reload();
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const middlewareLink = new ApolloLink((operation, forward) => {
  const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));

  operation.setContext({
    headers: {
      authorization: jwtAuth ? `Bearer ${jwtAuth.token}` : '',
    },
  });
  return forward(operation);
});
// const link = middlewareLink.concat(httpLink);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([middlewareLink, errorLink, httpLink]),
  fetchOptions: {
    mode: 'cors',
  },
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('app'),
);
