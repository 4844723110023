import React from 'react';
import NavLinkBase from './NavLink';
import { RouterContext } from '../../../contexts/RouteContext';

const navLink = props => (
  <RouterContext.Consumer>
    {location => <NavLinkBase location={location} {...props} />}
  </RouterContext.Consumer>
);

export default navLink;
