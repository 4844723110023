import { useEffect, useState } from 'react';

export const KeyboardNav = (step, isValid, navigation) => {
  const { next, previous } = navigation;

  const keyDownHandler = e => {
    e.key === 'Enter' && isValid ? next() : null;
    // e.key === 'ArrowLeft' && step.id !== 'stepOne' && step.id !== 'stepZero'
    //   ? previous()
    //   : null;
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [isValid]);
};
