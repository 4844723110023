import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function validateEmail(email) {
  const validate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validate.test(email);
}

const ReqInfo = ({ setForm, formData, navigation, step, index }) => {
  const {
    requestorName,
    requestorTitle,
    requestorTeam,
    requestorEmail,
    requestorPhone,
    requestorCompany,
  } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState({ err: '' });
  const emailFeild = createRef();

  const prevEmail = usePrevious(requestorEmail);
  KeyboardNav(step, isValid, navigation);

  function emailOnFocus() {
    if (requestorEmail !== '' && !validateEmail(requestorEmail)) {
      setEmailError({ err: 'Email is not Valid!' });
    } else {
      setEmailError({ err: '' });
    }
  }

  useEffect(() => {
    if (
      requestorName !== '' &&
      requestorTitle !== '' &&
      requestorTeam !== '' &&
      requestorEmail !== '' &&
      requestorPhone !== '' &&
      requestorCompany !== '' &&
      validateEmail(requestorEmail)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (
      prevEmail &&
      prevEmail.length > requestorEmail.length &&
      !validateEmail(requestorEmail)
    ) {
      setEmailError({ err: 'Email is not Valid!' });
    } else if (validateEmail(requestorEmail)) {
      setEmailError({ err: '' });
    }

    emailFeild.current.addEventListener('focusout', emailOnFocus);

    return () => {
      emailFeild.current
        ? emailFeild.current.removeEventListener('focusout', emailOnFocus)
        : null;
    };
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>{index + 1}</span> Requestor Information
      </h4>
      <Input
        name="requestorName"
        placeholder="Name *"
        value={requestorName}
        onChange={setForm}
      />
      <Input
        name="requestorTitle"
        placeholder="Title *"
        value={requestorTitle}
        onChange={setForm}
        half
      />
      <Input
        name="requestorTeam"
        placeholder="Department/Team *"
        value={requestorTeam}
        onChange={setForm}
        half
      />

      <div className={cx(stylesForm.input__wrapper, stylesForm.half)}>
        <label
          htmlFor="requestorEmail"
          className={cx(
            stylesForm.input,
            emailError.err !== '' ? stylesForm.input__email_error : '',
          )}
        >
          <input
            type="text"
            placeholder=" "
            id="requestorEmail"
            name="requestorEmail"
            value={requestorEmail}
            onChange={setForm}
            ref={emailFeild}
            autoComplete="nope"
          />
          <span className={stylesForm.label}>Email *</span>
          <span className={stylesForm.border} />
          {emailError.err !== '' && (
            <span className={stylesForm.email__error}>{emailError.err}</span>
          )}
        </label>
      </div>

      <Input
        name="requestorPhone"
        placeholder="Phone *"
        value={requestorPhone}
        onChange={setForm}
        half
      />

      <div className={stylesForm.checkbox__small}>
        <input
          type="radio"
          id="HPE"
          value="HPE"
          name="requestorCompany"
          checked={requestorCompany === 'HPE'}
          onChange={setForm}
        />
        <label htmlFor="HPE">HPE *</label>
      </div>
      <div className={stylesForm.checkbox__small}>
        <input
          type="radio"
          id="HP"
          value="HP"
          name="requestorCompany"
          checked={requestorCompany === 'HP'}
          onChange={setForm}
        />
        <label htmlFor="HP">HP *</label>
      </div>
      <div className={stylesForm.checkbox__small}>
        <input
          type="radio"
          id="HPCA"
          value="HPCA"
          name="requestorCompany"
          checked={requestorCompany === 'HPCA'}
          onChange={setForm}
        />
        <label htmlFor="HPCA">HPCA *</label>
      </div>

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default ReqInfo;
