/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

export const cateringList = [
  {
    id: 'yes',
    label: 'Yes',
  },
  {
    id: 'no',
    label: 'No',
  },
];

const Catering = ({ setForm, formData, navigation, step, index }) => {
  const { catering } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);
  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (catering !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <div>
      <h4>
        <span>{index + 1}</span> Is Catering Required? *
      </h4>

      <div className={cx(styles.form__multiple, styles.form__multiple__col1)}>
        {cateringList.map(type => {
          const { id, label } = type;

          return (
            <Radio
              id={id}
              key={id}
              label={label}
              name="catering"
              value={id}
              checked={catering === id}
              onChange={setForm}
              type="radio"
            />
          );
        })}
      </div>

      <Navigation navigation={navigation} isValid={isValid} />
    </div>
  );
};

export default Catering;
