import React from 'react';
import styles from './Form.scss';

const Dropdown = ({ label, options, ...others }) => (
  <>
    <label className={styles.dropdown__input__label}>{label}</label>
    <select {...others} className={styles.dropdown__input__select}>
      {options &&
        options.map(([value, name]) => (
          <option value={value} key={name}>
            {name}
          </option>
        ))}
    </select>
  </>
);

export default Dropdown;
