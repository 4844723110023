import React, { useState, useEffect, createRef, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import * as ROUTES from '../../constants/routes';

import styles from './Admin.scss';
import Header from '../../components/Header';
import AdminTour from './AdminTour';
import AdminEvent from './AdminEvent';
import AdminTourGuideAvailable from './AdminTourGuideAvailable';
import AdminTourGuideUpcoming from './AdminTourGuideUpcoming';

const Admin = props => {
  const { auth, history, match } = props;
  // console.log('🚀 ~ file: index.js:16 ~ Admin ~ auth', auth);
  useEffect(() => {
    const jwtAuth = JSON.parse(localStorage.getItem('jwtAuth'));
    !jwtAuth ? history.push(ROUTES.LOGIN) : null;
  }, []);

  useEffect(() => {
    if (auth.userRole === 'tourGuide') {
      history.push(ROUTES.ADMIN_TOURGUIDE_AVAILABLE);
    }
    if (auth.userRole === 'admin' || auth.userRole === 'manager') {
      history.push(ROUTES.ADMIN_TOUR);
    }
  }, [auth]);
  const HeaderWithRouter = withRouter(Header);

  function currentPage(page) {
    switch (page) {
      case ROUTES.ADMIN_TOUR:
        return <AdminTour {...props} />;
      case ROUTES.ADMIN_TOUR_SPECIAL:
        return <AdminEvent {...props} />;
      case ROUTES.ADMIN_CALENDAR:
        return <p>Calendar page coming soon!</p>;
      case ROUTES.ADMIN_TOURGUIDE_AVAILABLE:
        return <AdminTourGuideAvailable {...props} />;
      case ROUTES.ADMIN_TOURGUIDE_UPCOMING:
        return <AdminTourGuideUpcoming {...props} />;
      default:
        return <p>Page not found</p>;
    }
  }

  return (
    <div className={styles.admin}>
      <HeaderWithRouter />
      {currentPage(match.path)}
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <AuthContext.Consumer>
    {auth => <Admin {...props} auth={auth} ref={ref} />}
  </AuthContext.Consumer>
));
