import React from 'react';
import styles from './BoxRow.scss';
import { pageContext } from '../../../contexts/PageContext';
import { TimesArrayContext } from '../../../contexts/TimesArray';

import ToursRowItems from './ToursRowItems';
import EventsRowItems from './EventsRowItems';
import TourGuideRowItems from './TourGuideRowItems';

function BoxRow({ item }) {
  const timesArray = React.useContext(TimesArrayContext);

  function currentPage(page) {
    switch (page) {
      case 'tours':
        return (
          <ToursRowItems item={item} page={page} timesArray={timesArray} />
        );
      case 'events':
        return (
          <EventsRowItems item={item} page={page} timesArray={timesArray} />
        );
      case 'tourGuides':
        return (
          <TourGuideRowItems item={item} page={page} timesArray={timesArray} />
        );
      default:
        return <p>Page not found</p>;
    }
  }

  return (
    <pageContext.Consumer>{page => currentPage(page)}</pageContext.Consumer>
  );
}

export default BoxRow;
