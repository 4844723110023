import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const NumAttendees = ({ setForm, formData, navigation, step, index }) => {
  const { numAttendees } = formData;
  const { next, previous, go } = navigation;
  const [isValid, setIsValid] = useState(false);
  const [numError, setNumError] = useState({ err: '' });

  const numFeild = createRef();

  function validateNum(num) {
    const validate = /^[0-9]*$/;
    return validate.test(num);
  }

  function validateAmount(num) {
    return num > 20;
  }

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (
      numAttendees !== '' &&
      validateNum(numAttendees) &&
      !validateAmount(numAttendees)
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (!validateNum(numAttendees)) {
      setNumError({ err: 'Number is not Valid!' });
    } else if (validateAmount(numAttendees)) {
      setNumError({ err: 'Number must be between 1 and 20' });
    } else {
      setNumError({ err: '' });
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>{index + 1}</span> Number of guests?
      </h4>

      <label
        htmlFor="numAttendees"
        className={cx(
          stylesForm.input,
          numError.err !== '' ? stylesForm.input__email_error : '',
        )}
      >
        <input
          type="text"
          placeholder=" "
          id="numAttendees"
          name="numAttendees"
          value={numAttendees}
          onChange={setForm}
          ref={numFeild}
          autoComplete="off"
        />
        <span className={stylesForm.label}>Maximum guests is 20 people. *</span>
        <span className={stylesForm.border} />
        {numError.err !== '' && (
          <span className={stylesForm.email__error}>{numError.err}</span>
        )}
      </label>

      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default NumAttendees;
