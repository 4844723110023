import React, { useState, useEffect } from 'react';

import {
  Input,
  Radio,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';

const Notes = ({ setForm, formData, navigation, step, index }) => {
  const { notes } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(true);

  KeyboardNav(step, isValid, navigation);
  useEffect(() => {}, [formData, isValid]);
  return (
    <>
      <h4>
        <span>{index + 1}</span> Any Additional Notes or Questions?
      </h4>

      <Input
        name="notes"
        placeholder="Notes"
        value={notes}
        onChange={setForm}
        type="textarea"
      />
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default Notes;
