import React, { useState, useEffect, createRef, useRef } from 'react';
import cx from 'classnames';

import {
  Button,
  Input,
  Navigation,
  KeyboardNav,
} from '../../../../../components/shared';

import styles from '../../../Forms.scss';
import stylesForm from '../../../../../components/shared/Form/Form.scss';

const TourPurpose = ({ setForm, formData, navigation, step, index }) => {
  const { tourPurpose } = formData;
  const { next, previous } = navigation;
  const [isValid, setIsValid] = useState(false);

  KeyboardNav(step, isValid, navigation);

  useEffect(() => {
    if (tourPurpose !== '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, isValid]);

  return (
    <>
      <h4>
        <span>{index + 1}</span> Purpose of Event?
      </h4>
      <Input
        name="tourPurpose"
        placeholder="Sales Opportunity, Staff Recognition, Media Event, etc. *"
        value={tourPurpose}
        onChange={setForm}
        type="textarea"
      />
      <Navigation navigation={navigation} isValid={isValid} />
    </>
  );
};

export default TourPurpose;
