import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Input, Checkbox, Navigation, KeyboardNav } from '..';

import styles from './Modal.scss';
import stylesForm from '../Form/Form.scss';

const GET_TOUR_GUIDES = gql`
  query AllTourGuides($limit: Int) {
    allTourGuides(limit: $limit) {
      id
      email
      firstName
      lastName
      password
      role
      token
      name
    }
  }
`;

const SelectTourGuide = ({ modalData, setOpenModal, setModalData }) => {
  const [isValid, setIsValid] = useState(false);
  const [tourGuides, setTourGuides] = useState();
  const { loading: getUsersLoading, error, data } = useQuery(GET_TOUR_GUIDES);

  const tourGuideSlectHandler = e => {
    const index = tourGuides.findIndex(x => x.id === e.target.id);
    const _tourGuides = [...tourGuides];
    _tourGuides[index].selected = !_tourGuides[index].selected;

    setTourGuides(_tourGuides);
  };

  useMemo(() => {
    if (data) {
      const _tourGuides = [];
      data.allTourGuides.forEach(item => {
        _tourGuides.push({ ...item, selected: false });
      });
      setTourGuides(_tourGuides);
    }
  }, [data]);

  useEffect(() => {
    tourGuides ? setIsValid(!tourGuides.every(tour => !tour.selected)) : null;
  }, [tourGuides, getUsersLoading, isValid]);

  if (getUsersLoading) return <p>Loading ...</p>;

  return (
    <>
      <h5>Choose as many as you like</h5>
      <div className={styles.modal__content__body__checkbox}>
        {tourGuides &&
          tourGuides.map(tourGuide => {
            const { id, firstName, lastName, selected } = tourGuide;
            return (
              <div key={id} className={stylesForm.checkbox__small}>
                <input
                  type="checkbox"
                  id={id}
                  checked={selected}
                  onChange={tourGuideSlectHandler}
                />
                <label htmlFor={id}>
                  {lastName ? `${firstName} ${lastName}` : firstName}
                </label>
              </div>
            );
          })}
      </div>
      <div className={styles.modal__content__actions}>
        <button
          className={cx(styles.btn, styles.btn__close)}
          onClick={() => {
            setOpenModal(false);
            setModalData({ openModa: false });
          }}
        >
          Close
        </button>
        <button
          className={cx(styles.btn)}
          onClick={() => {
            modalData.onConfirm(tourGuides);
          }}
          disabled={!isValid}
        >
          Select & Approve
        </button>
      </div>
    </>
  );
};

export default SelectTourGuide;
