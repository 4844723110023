import React from 'react';

export const TimesArrayContext = React.createContext([
  { id: 1, label: '9:00 AM' },
  { id: 2, label: '9:15 AM' },
  { id: 3, label: '9:30 AM' },
  { id: 4, label: '9:45 AM' },
  { id: 5, label: '10:00 AM' },
  { id: 6, label: '10:15 AM' },
  { id: 7, label: '10:30 AM' },
  { id: 8, label: '10:45 AM' },
  { id: 9, label: '11:00 AM' },
  { id: 10, label: '11:15 AM' },
  { id: 11, label: '11:30 AM' },
  { id: 12, label: '11:45 AM' },
  { id: 13, label: '12:00 PM' },
  { id: 14, label: '12:15 PM' },
  { id: 15, label: '12:30 PM' },
  { id: 16, label: '12:45 PM' },
  { id: 17, label: '1:00 PM' },
  { id: 18, label: '1:15 PM' },
  { id: 19, label: '1:30 PM' },
  { id: 20, label: '1:45 PM' },
  { id: 21, label: '2:00 PM' },
  { id: 22, label: '2:15 PM' },
  { id: 23, label: '2:30 PM' },
  { id: 24, label: '2:45 PM' },
  { id: 25, label: '3:00 PM' },
  { id: 26, label: '3:15 PM' },
  { id: 27, label: '3:30 PM' },
  { id: 28, label: '3:45 PM' },
  { id: 29, label: '4:00 PM' },
  { id: 30, label: '4:15 PM' },
  { id: 31, label: '4:30 PM' },
  { id: 32, label: '4:45 PM' },
]);
